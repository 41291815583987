import {ExcalidrawElement} from "$/excalidraw/element/types"
import {AppState} from "$/excalidraw/types"
import {clearAppStateForLocalStorage, getDefaultAppState,} from "$/excalidraw/appState"
import {clearElementsForLocalStorage} from "$/excalidraw/element"
import {Local} from "@/enum/Local"

/**
 * Utility class for interacting with localStorage.
 */
export class LocalStorage {

    /**
     * Save the username to localStorage.
     * @param username The username to save.
     */
    public static saveUsernameToLocalStorage = (username: string) => {
        try {
            localStorage.setItem(Local.LOCAL_STORAGE_COLLAB, JSON.stringify({username}))
        } catch (error: any) {
            /** Unable to access localStorage */
            console.error(error)
        }
    }

    /**
     * Retrieve the username from localStorage.
     * @returns The stored username or null if not found.
     */
    public static importUsernameFromLocalStorage = (): string | null => {
        try {
            const data = localStorage.getItem(Local.LOCAL_STORAGE_COLLAB)
            if (data) {
                return JSON.parse(data).username
            }
        } catch (error: any) {
            /** Unable to access localStorage */
            console.error(error)
        }

        return null
    }

    /**
     * Import elements and appState from localStorage.
     * @returns An object containing imported elements and appState.
     */
    public static importFromLocalStorage = () => {
        let savedElements = null

        let savedState = null

        try {
            savedElements = localStorage.getItem(Local.LOCAL_STORAGE_ELEMENTS)

            savedState = localStorage.getItem(Local.LOCAL_STORAGE_APP_STATE)
        } catch (error: any) {
            /** Unable to access localStorage */
            console.error(error)
        }

        let elements: ExcalidrawElement[] = []

        if (savedElements) {
            try {
                elements = clearElementsForLocalStorage(JSON.parse(savedElements))
            } catch (error: any) {
                console.error(error)
                /** Do nothing because elements array is already empty */
            }
        }

        let appState = null
        if (savedState) {
            try {
                appState = {
                    ...getDefaultAppState(),
                    ...clearAppStateForLocalStorage(
                        JSON.parse(savedState) as Partial<AppState>,
                    ),
                }
            } catch (error: any) {
                console.error(error)
                /** Do nothing because elements array is already empty */
            }
        }
        return {elements, appState}
    }

    /**
     * Get the size of the elements stored in localStorage.
     * @returns The size of elements storage.
     */
    public static getElementsStorageSize = () => {
        try {
            const elements = localStorage.getItem(Local.LOCAL_STORAGE_ELEMENTS)
            return elements?.length || 0
        } catch (error: any) {
            console.error(error)
            return 0
        }
    }

    /**
     * Get the total size of all storage in localStorage.
     * @returns The total size of localStorage storage.
     */
    public static getTotalStorageSize = () => {
        try {
            const appState = localStorage.getItem(Local.LOCAL_STORAGE_APP_STATE)
            const collab = localStorage.getItem(Local.LOCAL_STORAGE_COLLAB)

            const appStateSize = appState?.length || 0
            const collabSize = collab?.length || 0

            return appStateSize + collabSize + this.getElementsStorageSize()
        } catch (error: any) {
            console.error(error)
            return 0
        }
    }
}
