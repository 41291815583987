import { useSetAtom } from "jotai"
import React from "react"
import { useI18n } from "$/excalidraw"
import { languages } from "$/excalidraw"
import {AppStore} from "@/store/AppStore"

export const LanguageList = ({ style }: { style?: React.CSSProperties }) => {
  const { t, langCode } = useI18n()

  const setLangCode = useSetAtom(AppStore.langCode)

  return (
    <select
      className="dropdown-select dropdown-select__language"
      onChange={({ target }) => setLangCode(target.value)}
      value={langCode}
      aria-label={t("buttons.selectLanguage")}
      style={style}
    >
      {languages.map((lang) => (
        <option key={lang.code} value={lang.code}>
          {lang.label}
        </option>
      ))}
    </select>
  )
}
