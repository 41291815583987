/**
 * Configuration class containing various time constants in milliseconds.
 */
export class BaseConfig {

    /**
     * Timeout for saving to local storage.
     */
    public static APP_NAME = import.meta.env.VITE_APP_NAME

    /**
     * Timeout for saving to local storage.
     */
    public static SAVE_TO_LOCAL_STORAGE_TIMEOUT = 300

    /**
     * Timeout for initial scene update.
     */
    public static INITIAL_SCENE_UPDATE_TIMEOUT = 5000

    /**
     * File upload timeout.
     */
    public static FILE_UPLOAD_TIMEOUT = 300

    /**
     * Timeout for loading images.
     */
    public static LOAD_IMAGES_TIMEOUT = 500

    /**
     * Interval for syncing the full scene.
     */
    public static SYNC_FULL_SCENE_INTERVAL_MS = 20000

    /**
     * Timeout for syncing browser tabs.
     */
    public static SYNC_BROWSER_TABS_TIMEOUT = 50

    /**
     * Timeout for cursor sync (approximately 30fps).
     */
    public static CURSOR_SYNC_TIMEOUT = 33

    /**
     * Timeout for deleted elements (1 day).
     */
    public static DELETED_ELEMENT_TIMEOUT = 24 * 60 * 60 * 1000

    /**
     * Maximum file upload size in bytes (3 MiB).
     */
    public static FILE_UPLOAD_MAX_BYTES = 3 * 1024 * 1024

    /**
     * Maximum age of files in cache (1 year).
     */
    public static FILE_CACHE_MAX_AGE_SEC = 31536000

    /**
     * Number of bytes for room ID generation.
     */
    public static ROOM_ID_BYTES = 10

    /**
     * Firebase storage prefixes for different types of files.
     */
    public static FIREBASE_STORAGE_PREFIXES = {
        /**
         * Prefix for share link files.
         */
        shareLinkFiles: `/files/shareLinks`,

        /**
         * Prefix for collaborative room files.
         */
        collabFiles: `/files/rooms`,
    }
}
