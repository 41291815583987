/**
 * Locker
 */
export class Locker<T extends string> {
    /**
     * locks
     * @private
     */
    private locks = new Map<T, true>()

    /**
     * lock
     * @param lockType
     */
    public lock = (lockType: T) => {
        this.locks.set(lockType, true)
    }

    /**
     * @returns whether no locks remaining
     */
    public unlock = (lockType: T) => {
        this.locks.delete(lockType)
        return !this.isLocked()
    }

    /**
     * @returns whether some (or specific) locks are present
     */
    public isLocked(lockType?: T) {
        return lockType ? this.locks.has(lockType) : !!this.locks.size
    }
}
