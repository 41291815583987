import {Local} from "@/enum/Local"

/**
 * in-memory state (this tab's current state) versions. Currently just
 * timestamps of the last time the state was saved to browser storage.
 */
const LOCAL_STATE_VERSIONS = {
    [Local.LOCAL_STORAGE_VERSION_DATA_STATE]: -1,
    [Local.LOCAL_STORAGE_VERSION_FILES]: -1,
}

type BrowserStateTypes = keyof typeof LOCAL_STATE_VERSIONS

export class TabSync {

    /**
     * isBrowserStorageStateNewer
     * @param type
     */
    public static isBrowserStorageStateNewer = (type: BrowserStateTypes) => {
        const storageTimestamp = JSON.parse(localStorage.getItem(type as string) || "-1")

        return storageTimestamp > LOCAL_STATE_VERSIONS[type]
    }

    /**
     * updateBrowserStateVersion
     * @param type
     */
    public static updateBrowserStateVersion = (type: BrowserStateTypes) => {
        const timestamp = Date.now()
        try {
            localStorage.setItem(type as string, JSON.stringify(timestamp))
            LOCAL_STATE_VERSIONS[type] = timestamp
        } catch (error) {
            console.error("error while updating browser state verison", error)
        }
    }

    /**
     * resetBrowserStateVersions
     */
    public static resetBrowserStateVersions = () => {
        try {
            for (const key of Object.keys(
                LOCAL_STATE_VERSIONS,
            ) as BrowserStateTypes[]) {
                const timestamp = -1
                localStorage.setItem(key as string, JSON.stringify(timestamp))
                LOCAL_STATE_VERSIONS[key] = timestamp
            }
        } catch (error) {
            console.error("error while resetting browser state verison", error)
        }
    }

}
