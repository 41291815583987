import {
    ExcalidrawElement,
    FileId,
} from "$/excalidraw/element/types"
import {getSceneVersion} from "$/excalidraw"
import Portal from "../collab/portal"
import {restoreElements} from "$/excalidraw"
import {
    AppState,
    BinaryFileData,
    BinaryFileMetadata,
    DataURL,
} from "$/excalidraw/types"
import {decompressData} from "$/excalidraw/data/encode"
import {
    encryptData,
    decryptData,
} from "$/excalidraw/data/encryption"
import {MIME_TYPES} from "$/excalidraw"
import {Reconciliation} from "@/collab/reconciliation"
import {Data, SyncableExcalidrawElement} from "."
import {ResolutionType} from "$/excalidraw/utility-types"
import type {Socket} from "socket.io-client"
import {BaseConfig} from "@/config/BaseConfig"

/**
 * Interface representing the structure of a Firebase stored scene with version, initialization vector (iv), and ciphertext.
 */
interface FirebaseStoredScene {
    sceneVersion: number
    iv: firebase.default.firestore.Blob
    ciphertext: firebase.default.firestore.Blob
}

/**
 * Class to cache scene versions associated with sockets.
 */
class FirebaseSceneVersionCache {
    /**
     * cache
     * @private
     */
    private static cache = new WeakMap<Socket, number>()

    /**
     * Get the cached scene version for the provided socket.
     *
     * @param {Socket} socket - The socket for which to retrieve the scene version
     * @returns {number | undefined} - The cached scene version or undefined if not found
     */
    public static get = (socket: Socket) => {
        return FirebaseSceneVersionCache.cache.get(socket)
    }

    /**
     * Set the scene version in the cache for the given socket based on the provided elements.
     *
     * @param {Socket} socket - The socket for which to set the scene version
     * @param {readonly SyncableExcalidrawElement[]} elements - Elements to determine the scene version
     */
    public static set = (socket: Socket, elements: readonly SyncableExcalidrawElement[]) => {
        FirebaseSceneVersionCache.cache.set(socket, getSceneVersion(elements))
    }
}

/**
 * Class for managing Firebase configurations and promises.
 */
export class FireBase {
    /**
     * Firebase configuration object.
     */
    public static firebaseConfig: Record<string, any>

    /**
     * Promise for Firebase initialization.
     */
    public static firebasePromise: Promise<typeof import("firebase/app").default> | null = null

    /**
     * Promise for Firestore initialization.
     */
    public static firestorePromise: Promise<any> | null | true = null

    /**
     * Promise for Firebase Storage initialization.
     */
    public static firebaseStoragePromise: Promise<any> | null | true = null

    /**
     * Flag to indicate if Firebase is initialized.
     */
    public static isFirebaseInitialized = false

    /**
     * Initialize the Firebase configuration by parsing the provided config value.
     */
    public static init = () => {
        try {
            this.firebaseConfig = JSON.parse(import.meta.env.VITE_APP_FIREBASE_CONFIG)

            console.log('init firebash')
        } catch (error: any) {
            console.warn(`Error JSON parsing firebase config. Supplied value: ${import.meta.env.VITE_APP_FIREBASE_CONFIG}`)
            this.firebaseConfig = {}
        }
    }

    /**
     * Asynchronously load Firestore and return the initialized Firebase instance.
     *
     * @returns {Promise<typeof import("firebase/app").default>} - A promise resolving with the initialized Firebase instance
     */
    public static loadFirestore = async () => {
        const firebase = await this._getFirebase()

        if (!this.firestorePromise) {
            this.firestorePromise = import( "firebase/firestore")
        }

        if (this.firestorePromise !== true) {
            await this.firestorePromise

            this.firestorePromise = true
        }

        return firebase
    }

    /**
     * Asynchronously load Firebase Storage and return the initialized Firebase instance.
     *
     * @returns {Promise<typeof import("firebase/app").default>} - A promise resolving with the initialized Firebase instance
     */
    public static loadFirebaseStorage = async () => {
        const firebase = await this._getFirebase()

        if (!this.firebaseStoragePromise) {
            this.firebaseStoragePromise = import( "firebase/storage")
        }

        if (this.firebaseStoragePromise !== true) {
            await this.firebaseStoragePromise

            this.firebaseStoragePromise = true
        }

        return firebase
    }

    /**
     * Asynchronously encrypt Excalidraw elements using a key and return the ciphertext and initialization vector.
     *
     * @param {string} key - Encryption key
     * @param {readonly ExcalidrawElement[]} elements - Array of Excalidraw elements to encrypt
     * @returns {Promise<{ ciphertext: ArrayBuffer iv: Uint8Array }>} - Promise resolving with ciphertext and initialization vector
     */
    public static encryptElements = async (
        key: string,
        elements: readonly ExcalidrawElement[],
    ): Promise<{ ciphertext: ArrayBuffer; iv: Uint8Array }> => {
        const json = JSON.stringify(elements)

        const encoded = new TextEncoder().encode(json)

        const {encryptedBuffer, iv} = await encryptData(key, encoded)

        return {ciphertext: encryptedBuffer, iv}
    }

    /**
     * Asynchronously decrypt Firebase stored scene data using a room key and return the decrypted Excalidraw elements.
     *
     * @param {FirebaseStoredScene} data - Firebase stored scene data containing ciphertext and initialization vector
     * @param {string} roomKey - Room key for decryption
     * @returns {Promise<readonly ExcalidrawElement[]>} - Promise resolving with decrypted Excalidraw elements
     */
    public static decryptElements = async (
        data: FirebaseStoredScene,
        roomKey: string,
    ): Promise<readonly ExcalidrawElement[]> => {
        const ciphertext = data.ciphertext.toUint8Array()

        const iv = data.iv.toUint8Array()

        const decrypted = await decryptData(iv, ciphertext, roomKey)

        const decodedData = new TextDecoder("utf-8").decode(
            new Uint8Array(decrypted),
        )

        return JSON.parse(decodedData)
    }

    /**
     * Check if the elements are saved to Firebase based on the portal information.
     *
     * @param {Portal} portal - Portal object containing socket, roomId, and roomKey
     * @param {readonly ExcalidrawElement[]} elements - Array of Excalidraw elements to check
     * @returns {boolean} - true if the elements are saved to Firebase, false otherwise
     */
    public static isSavedToFirebase = (
        portal: Portal,
        elements: readonly ExcalidrawElement[],
    ): boolean => {
        if (portal.socket && portal.roomId && portal.roomKey) {
            const sceneVersion = getSceneVersion(elements)

            return FirebaseSceneVersionCache.get(portal.socket) === sceneVersion
        }
        /**
         * If no room exists, consider the room saved so that we don't unnecessarily
         * prevent unload (there's nothing we could do at that point anyway)
         */
        return true
    }

    /**
     * Asynchronously save files to Firebase Storage with a specified prefix.
     *
     * @param {Object} param - Object containing prefix and files array
     * @returns {Promise<{savedFiles: Map<FileId, true>, erroredFiles: Map<FileId, true>}>} - Promise resolving with saved files map and errored files map
     */
    public static saveFilesToFirebase = async ({prefix, files}: {
        prefix: string
        files: { id: FileId; buffer: Uint8Array }[]
    }) => {
        const firebase = await this.loadFirebaseStorage()

        const erroredFiles = new Map<FileId, true>()

        const savedFiles = new Map<FileId, true>()

        await Promise.all(
            files.map(async ({id, buffer}) => {
                try {
                    await firebase
                        .storage()
                        .ref(`${prefix}/${id}`)
                        .put(
                            new Blob([buffer], {
                                type: MIME_TYPES.binary,
                            }),
                            {
                                cacheControl: `public, max-age=${BaseConfig.FILE_CACHE_MAX_AGE_SEC}`,
                            },
                        )
                    savedFiles.set(id, true)
                } catch (error: any) {
                    erroredFiles.set(id, true)
                }
            }),
        )

        return {savedFiles, erroredFiles}
    }

    /**
     * Asynchronously create a Firebase scene document with encrypted elements.
     *
     * @param {ResolutionType<typeof this.loadFirestore>} firebase - Firebase instance obtained from loadFirestore function
     * @param {readonly SyncableExcalidrawElement[]} elements - Array of syncable Excalidraw elements to encrypt
     * @param {string} roomKey - Room key for encryption
     * @returns {Promise<FirebaseStoredScene>} - Promise resolving with the created Firebase stored scene document
     */
    public static createFirebaseSceneDocument = async (
        firebase: ResolutionType<typeof this.loadFirestore>,
        elements: readonly SyncableExcalidrawElement[],
        roomKey: string,
    ) => {
        const sceneVersion = getSceneVersion(elements)

        const {ciphertext, iv} = await this.encryptElements(roomKey, elements)

        return {
            sceneVersion,
            ciphertext: firebase.firestore.Blob.fromUint8Array(
                new Uint8Array(ciphertext),
            ),
            iv: firebase.firestore.Blob.fromUint8Array(iv),
        } as FirebaseStoredScene
    }

    /**
     * Asynchronously save elements to Firebase with transactional handling.
     *
     * @param {Portal} portal - Portal object containing room information
     * @param {readonly SyncableExcalidrawElement[]} elements - Array of syncable Excalidraw elements to save
     * @param {AppState} appState - Application state
     * @returns {Promise<{ reconciledElements: readonly SyncableExcalidrawElement[] | null }>} - Promise resolving with reconciled elements or null
     */
    public static saveToFirebase = async (
        portal: Portal,
        elements: readonly SyncableExcalidrawElement[],
        appState: AppState,
    ) => {
        const {roomId, roomKey, socket} = portal
        /**
         * bail if no room exists as there's nothing we can do at this point
         */
        if (!roomId || !roomKey || !socket || this.isSavedToFirebase(portal, elements)) {
            return false
        }

        const firebase = await this.loadFirestore()

        const firestore = firebase.firestore()

        const docRef = firestore.collection("scenes").doc(roomId)

        const savedData = await firestore.runTransaction(async (transaction) => {
            const snapshot = await transaction.get(docRef)

            if (!snapshot.exists) {
                const sceneDocument = await this.createFirebaseSceneDocument(
                    firebase,
                    elements,
                    roomKey,
                )

                transaction.set(docRef, sceneDocument)

                return {
                    elements,
                    reconciledElements: null,
                }
            }

            const prevDocData = snapshot.data() as FirebaseStoredScene

            const prevElements = Data.getSyncableElements(
                await this.decryptElements(prevDocData, roomKey),
            )

            const reconciledElements = Data.getSyncableElements(
                Reconciliation.reconcileElements(elements, prevElements, appState),
            )

            const sceneDocument = await this.createFirebaseSceneDocument(
                firebase,
                reconciledElements,
                roomKey,
            )

            transaction.update(docRef, sceneDocument)

            return {
                elements,
                reconciledElements,
            }
        })

        FirebaseSceneVersionCache.set(socket, savedData.elements)

        return {reconciledElements: savedData.reconciledElements}
    }

    /**
     * Asynchronously load Excalidraw elements from Firebase based on room information.
     *
     * @param {string} roomId - Room ID for retrieving scene data
     * @param {string} roomKey - Room key for decryption
     * @param {Socket | null} socket - Socket associated with the room
     * @returns {Promise<readonly ExcalidrawElement[] | null>} - Promise resolving with loaded Excalidraw elements or null
     */
    public static loadFromFirebase = async (
        roomId: string,
        roomKey: string,
        socket: Socket | null,
    ): Promise<readonly ExcalidrawElement[] | null> => {
        const firebase = await this.loadFirestore()

        const db = firebase.firestore()

        const docRef = db.collection("scenes").doc(roomId)

        const doc = await docRef.get()

        if (!doc.exists) return null

        const storedScene = doc.data() as FirebaseStoredScene

        const elements = Data.getSyncableElements(
            await this.decryptElements(storedScene, roomKey),
        )

        if (socket) FirebaseSceneVersionCache.set(socket, elements)

        return restoreElements(elements, null)
    }

    /**
     * Asynchronously load files from Firebase Storage using provided prefix, decryption key, and file IDs.
     *
     * @param {string} prefix - Prefix for the storage path
     * @param {string} decryptionKey - Key used for decryption of files
     * @param {readonly FileId[]} filesIds - Array of file IDs to load
     * @returns {Promise<{loadedFiles: BinaryFileData[], erroredFiles: Map<FileId, true>}>} - Promise resolving with loaded files and errored files map
     */
    public static loadFilesFromFirebase = async (
        prefix: string,
        decryptionKey: string,
        filesIds: readonly FileId[],
    ) => {
        const loadedFiles: BinaryFileData[] = []

        const erroredFiles = new Map<FileId, true>()

        await Promise.all(
            [...new Set(filesIds)].map(async (id) => {
                try {
                    const url = `https://firebasestorage.googleapis.com/v0/b/${this.firebaseConfig.storageBucket}/o/${encodeURIComponent(prefix.replace(/^\//, ""))}%2F${id}`

                    const response = await fetch(`${url}?alt=media`)

                    if (response.status < 400) {
                        const arrayBuffer = await response.arrayBuffer()

                        const {data, metadata} = await decompressData<BinaryFileMetadata>(
                            new Uint8Array(arrayBuffer),
                            {
                                decryptionKey,
                            },
                        )

                        const dataURL = new TextDecoder().decode(data) as DataURL

                        loadedFiles.push({
                            mimeType: metadata.mimeType || MIME_TYPES.binary,
                            id,
                            dataURL,
                            created: metadata?.created || Date.now(),
                            lastRetrieved: metadata?.created || Date.now(),
                        })
                    } else {
                        erroredFiles.set(id, true)
                    }
                } catch (error: any) {
                    erroredFiles.set(id, true)

                    console.error(error)
                }
            }),
        )

        return {loadedFiles, erroredFiles}
    }

    /**
     * Asynchronously load Firebase instance and initialize if not already done.
     *
     * @returns {typeof import("firebase/app").default} - Resolves with the Firebase instance
     */
    private static _loadFirebase = async () => {
        const firebase = (await import( "firebase/app")).default

        if (!this.isFirebaseInitialized) {
            try {
                firebase.initializeApp(this.firebaseConfig)
            } catch (error: any) {
                /**
                 * trying initialize again throws. Usually this is harmless, and happens mainly in dev (HMR)
                 */
                if (error.code === "app/duplicate-app") {
                    console.warn(error.name, error.code)
                } else {
                    throw error
                }
            }

            this.isFirebaseInitialized = true
        }

        return firebase
    }

    /**
     * Asynchronously get the Firebase instance. If not already loaded, load it.
     *
     * @returns {Promise<typeof import("firebase/app").default>} - Resolves with the Firebase instance
     */
    private static _getFirebase = async (): Promise<typeof import("firebase/app").default> => {
        if (!this.firebasePromise) this.firebasePromise = this._loadFirebase()

        return this.firebasePromise
    }
}


FireBase.init()
