import {atom, unstable_createStore} from "jotai"
import LanguageDetector from "i18next-browser-languagedetector"

const languageDetector = new LanguageDetector()

languageDetector.init({languageUtils: {}})

const detectedLangCode = languageDetector.detect() || 'zh-CN'

export class AppStore {

    /**
     * app store
     */
    public static app = unstable_createStore()

    /**
     * language detector
     */
    public static languageDetector = languageDetector

    /**
     * lang code
     */
    public static langCode = atom(Array.isArray(detectedLangCode) ? detectedLangCode[0] : detectedLangCode)
}
