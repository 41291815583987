import throttle from "lodash.throttle"
import {PureComponent} from "react"
import {
    ExcalidrawImperativeAPI,
    SocketId,
} from "$/excalidraw/types"
import {ErrorDialog} from "$/excalidraw/components/ErrorDialog"
import {APP_NAME, ENV, EVENT} from "$/excalidraw/constants"
import {ImportedDataState} from "$/excalidraw/data/types"
import {
    ExcalidrawElement,
    InitializedExcalidrawImageElement,
} from "$/excalidraw/element/types"
import {
    getSceneVersion,
    restoreElements,
    zoomToFitBounds,
} from "$/excalidraw"
import {Collaborator, Gesture} from "$/excalidraw/types"
import {
    assertNever,
    preventUnload,
    resolvablePromise,
    throttleRAF,
} from "$/excalidraw/utils"

import {
    Data,
    SocketUpdateDataSource,
    SyncableExcalidrawElement,
} from "../data"
import {FireBase} from "../data/FireBase"
import {LocalStorage} from "../data/LocalStorage"
import Portal from "./portal"
import {t} from "$/excalidraw/i18n"
import {UserIdleState} from "$/excalidraw/types"
import {
    IDLE_THRESHOLD,
    ACTIVE_THRESHOLD,
} from "$/excalidraw/constants"
import {FileManager} from "../data/FileManager"
import {AbortError} from "$/excalidraw/errors"
import {
    isImageElement,
    isInitializedImageElement,
} from "$/excalidraw/element/typeChecks"
import {newElementWith} from "$/excalidraw/element/mutateElement"
import {
    ReconciledElements,
    Reconciliation
} from "./reconciliation"
import {decryptData} from "$/excalidraw/data/encryption"
import {TabSync} from "../data/TabSync"
import {LocalData} from "../data/LocalData"
import {Mutable, ValueOf} from "$/excalidraw/utility-types"
import {getVisibleSceneBounds} from "$/excalidraw/element/bounds"
import {withBatchedUpdates} from "$/excalidraw/reactUtils"
import {BaseConfig} from "../config/BaseConfig"
import {WSSub} from "../enum/WSSub"
import {AppStore} from "../store/AppStore"
import {WSEvent} from "../enum/WSEvent"
import {CollabStore} from "../store/CollabStore"

/**
 * Interface representing the state of the Collab component.
 *
 * @typedef {Object} CollabState
 * @property {string | null} errorMessage - The error message
 * @property {Record<string, boolean>} dialogNotifiedErrors - Errors related to saving
 * @property {string} username - The username
 * @property {string | null} activeRoomLink - The active room link
 */
interface CollabState {
    errorMessage: string | null
    dialogNotifiedErrors: Record<string, boolean>
    username: string
    activeRoomLink: string | null
}

/**
 * collab instance
 */
type CollabInstance = InstanceType<typeof Collab>;

/**
 * Interface for CollabAPI functions and methods.
 *
 * @typedef {Object} CollabAPI
 * @property {() => boolean} isCollaborating - Function to check if collaborating
 * @property {Function} onPointerUpdate - Method for pointer update
 * @property {Function} startCollaboration - Method to start collaboration
 * @property {Function} stopCollaboration - Method to stop collaboration
 * @property {Function} syncElements - Method to sync elements
 * @property {Function} fetchImageFilesFromFirebase - Method to fetch image files from Firebase
 * @property {Function} setUsername - Method to set username
 * @property {Function} getUsername - Method to get username
 * @property {Function} getActiveRoomLink - Method to get active room link
 * @property {Function} setCollabError - Method to set collaboration error
 */
export interface CollabAPI {
    isCollaborating: () => boolean
    onPointerUpdate: CollabInstance["onPointerUpdate"]
    startCollaboration: CollabInstance["startCollaboration"]
    stopCollaboration: CollabInstance["stopCollaboration"]
    syncElements: CollabInstance["syncElements"]
    fetchImageFilesFromFirebase: CollabInstance["fetchImageFilesFromFirebase"]
    setUsername: CollabInstance["setUsername"]
    getUsername: CollabInstance["getUsername"]
    getActiveRoomLink: CollabInstance["getActiveRoomLink"]
    setCollabError: CollabInstance["setErrorDialog"]
}

/**
 * Interface for CollabProps containing properties specific to the Collab component.
 *
 * @typedef {Object} CollabProps
 * @property {ExcalidrawImperativeAPI} excalidrawAPI - The Excalidraw API instance
 */
interface CollabProps {
    excalidrawAPI: ExcalidrawImperativeAPI
}

/**
 * Class representing Collab component.
 */
export class Collab extends PureComponent<CollabProps, CollabState> {
    /**
     * Instance of Portal
     */
    portal: Portal

    /**
     * FileManager instance
     */
    fileManager: FileManager

    /**
     * Excalidraw API instance
     */
    excalidrawAPI: CollabProps["excalidrawAPI"]

    /**
     * ID for active interval
     */
    activeIntervalId: number | null

    /**
     * ID for idle timeout
     */
    idleTimeoutId: number | null

    /**
     * Timer for socket initialization
     */
    private socketInitializationTimer?: number

    /**
     * Last scene version that was broadcasted or received
     */
    private lastBroadcastedOrReceivedSceneVersion: number = -1

    /**
     * Map to store collaborators
     */
    private collaborators = new Map<SocketId, Collaborator>()

    /**
     * Collab constructor
     * @param props
     */
    constructor(props: CollabProps) {
        super(props)

        this.state = {
            errorMessage: null,
            dialogNotifiedErrors: {},
            username: LocalStorage.importUsernameFromLocalStorage() || "",
            activeRoomLink: null,
        }

        this.portal = new Portal(this)

        this.fileManager = new FileManager({
            getFiles: async (fileIds) => {
                const {roomId, roomKey} = this.portal
                if (!roomId || !roomKey) {
                    throw new AbortError()
                }

                return FireBase.loadFilesFromFirebase(`files/rooms/${roomId}`, roomKey, fileIds)
            },
            saveFiles: async ({addedFiles}) => {
                const {roomId, roomKey} = this.portal

                if (!roomId || !roomKey) throw new AbortError()

                return FireBase.saveFilesToFirebase({
                    prefix: `${BaseConfig.FIREBASE_STORAGE_PREFIXES.collabFiles}/${roomId}`,
                    files: await FileManager.encodeFilesForUpload({
                        files: addedFiles,
                        encryptionKey: roomKey,
                        maxBytes: BaseConfig.FILE_UPLOAD_MAX_BYTES,
                    }),
                })
            },
        })

        this.excalidrawAPI = props.excalidrawAPI

        this.activeIntervalId = null

        this.idleTimeoutId = null
    }

    /**
     * on ummount
     * @private
     */
    private onUmmount: (() => void) | null = null

    /**
     * ComponentDidMount lifecycle method. Initialization tasks include event listeners setup, subscriptions, and API initialization.
     */
    componentDidMount() {
        window.addEventListener(EVENT.BEFORE_UNLOAD, this.beforeUnload)
        window.addEventListener("online", this.onOfflineStatusToggle)
        window.addEventListener("offline", this.onOfflineStatusToggle)
        window.addEventListener(EVENT.UNLOAD, this.onUnload)

        const unsubOnUserFollow = this.excalidrawAPI.onUserFollow((payload) => {
            this.portal.socket && this.portal.broadcastUserFollowed(payload)
        })

        const throttledRelayUserViewportBounds = throttleRAF(this.relayVisibleSceneBounds)

        const unsubOnScrollChange = this.excalidrawAPI.onScrollChange(() =>
            throttledRelayUserViewportBounds(),
        )

        this.onUmmount = () => {
            unsubOnUserFollow()
            unsubOnScrollChange()
        }

        this.onOfflineStatusToggle()

        const collabAPI: CollabAPI = {
            isCollaborating: this.isCollaborating,
            onPointerUpdate: this.onPointerUpdate,
            startCollaboration: this.startCollaboration,
            syncElements: this.syncElements,
            fetchImageFilesFromFirebase: this.fetchImageFilesFromFirebase,
            stopCollaboration: this.stopCollaboration,
            setUsername: this.setUsername,
            getUsername: this.getUsername,
            getActiveRoomLink: this.getActiveRoomLink,
            setCollabError: this.setErrorDialog,
        }

        AppStore.app.set(CollabStore.collabAPI, collabAPI)

        if (import.meta.env.MODE === ENV.TEST || import.meta.env.DEV) {
            window.collab = window.collab || ({} as Window["collab"])
            Object.defineProperties(window, {
                collab: {
                    configurable: true,
                    value: this,
                },
            })
        }
    }

    /**
     * ComponentWillUnmount lifecycle method. Cleans up event listeners and intervals set during component's lifecycle.
     */
    componentWillUnmount() {
        window.removeEventListener("online", this.onOfflineStatusToggle)
        window.removeEventListener("offline", this.onOfflineStatusToggle)
        window.removeEventListener(EVENT.BEFORE_UNLOAD, this.beforeUnload)
        window.removeEventListener(EVENT.UNLOAD, this.onUnload)
        window.removeEventListener(EVENT.POINTER_MOVE, this.onPointerMove)
        window.removeEventListener(
            EVENT.VISIBILITY_CHANGE,
            this.onVisibilityChange,
        )
        if (this.activeIntervalId) {
            window.clearInterval(this.activeIntervalId)
            this.activeIntervalId = null
        }
        if (this.idleTimeoutId) {
            window.clearTimeout(this.idleTimeoutId)
            this.idleTimeoutId = null
        }
        this.onUmmount?.()
    }

    /**
     * Function to toggle offline status in the AppStore based on window's online status.
     */
    onOfflineStatusToggle = () => {
        AppStore.app.set(CollabStore.isOffline, !window.navigator.onLine)
    }

    /**
     * Function to check if collaborating by fetching the value from AppStore.
     *
     * @returns {boolean} - True if collaborating, false otherwise
     */
    isCollaborating = () => AppStore.app.get(CollabStore.isCollaborating)!;

    /**
     * Function to set the collaborating status in the AppStore.
     *
     * @param {boolean} isCollaborating - Flag indicating collaboration status
     */
    private setIsCollaborating = (isCollaborating: boolean) => {
        AppStore.app.set(CollabStore.isCollaborating, isCollaborating)
    }

    /**
     * Method called on window unload event to destroy the socket client.
     */
    private onUnload = () => {
        this.destroySocketClient({isUnload: true})
    }

    /**
     * Method triggered on before unload event to handle saving and preventing page unload.
     */
    private beforeUnload = withBatchedUpdates((event: BeforeUnloadEvent) => {
        const syncableElements = Data.getSyncableElements(this.getSceneElementsIncludingDeleted())

        if (this.isCollaborating() && (this.fileManager.shouldPreventUnload(syncableElements) || !FireBase.isSavedToFirebase(this.portal, syncableElements))) {
            /**
             * This won't run in time if user decides to leave the site, but the purpose is to run immediately after the user decides to stay.
             */
            this.saveCollabRoomToFirebase(syncableElements)

            preventUnload(event)
        }
    })

    /**
     * Save collaboration room data to Firebase asynchronously.
     *
     * @param {readonly SyncableExcalidrawElement[]} syncableElements - Elements to save
     */
    saveCollabRoomToFirebase = async (
        syncableElements: readonly SyncableExcalidrawElement[],
    ) => {
        try {
            const savedData = await FireBase.saveToFirebase(
                this.portal,
                syncableElements,
                this.excalidrawAPI.getAppState(),
            )

            this.resetErrorIndicator()

            if (this.isCollaborating() && savedData && savedData.reconciledElements) {
                this.handleRemoteSceneUpdate(this.reconcileElements(savedData.reconciledElements),)
            }
        } catch (error: any) {
            const errorMessage = /is longer than.*?bytes/.test(error.message)
                ? t("errors.collabSaveFailed_sizeExceeded")
                : t("errors.collabSaveFailed")

            if (
                !this.state.dialogNotifiedErrors[errorMessage] ||
                !this.isCollaborating()
            ) {
                this.setErrorDialog(errorMessage)
                this.setState({
                    dialogNotifiedErrors: {
                        ...this.state.dialogNotifiedErrors,
                        [errorMessage]: true,
                    },
                })
            }

            if (this.isCollaborating()) this.setErrorIndicator(errorMessage)

            console.error(error)
        }
    }

    /**
     * Method to stop collaboration, with an option to keep or reset remote state.
     *
     * @param {boolean} keepRemoteState - Flag indicating whether to keep remote state
     */
    stopCollaboration = (keepRemoteState = true) => {
        this.queueBroadcastAllElements.cancel()

        this.queueSaveToFirebase.cancel()

        this.loadImageFiles.cancel()

        this.resetErrorIndicator(true)

        this.saveCollabRoomToFirebase(Data.getSyncableElements(
            this.excalidrawAPI.getSceneElementsIncludingDeleted(),
        ))

        if (this.portal.socket && this.fallbackInitializationHandler) {
            this.portal.socket.off(
                "connect_error",
                this.fallbackInitializationHandler,
            )
        }

        if (!keepRemoteState) {
            LocalData.fileStorage.reset()

            this.destroySocketClient()
        } else if (window.confirm(t("alerts.collabStopOverridePrompt"))) {
            /**
             * Hack to ensure that we disregard any new browser state that could have been saved in other tabs while collaborating.
             */
            TabSync.resetBrowserStateVersions()

            window.history.pushState({}, APP_NAME, window.location.origin)

            this.destroySocketClient()

            LocalData.fileStorage.reset()

            const elements = this.excalidrawAPI
                .getSceneElementsIncludingDeleted()
                .map((element) => {
                    if (isImageElement(element) && element.status === "saved") {
                        return newElementWith(element, {status: "pending"})
                    }
                    return element
                })

            this.excalidrawAPI.updateScene({
                elements,
                commitToHistory: false,
            })
        }
    }

    /**
     * Method to destroy the socket client, with optional behavior based on isUnload parameter.
     *
     * @param {Object} opts - Options object containing isUnload flag
     */
    private destroySocketClient = (opts?: { isUnload: boolean }) => {
        this.lastBroadcastedOrReceivedSceneVersion = -1

        this.portal.close()

        this.fileManager.reset()

        if (!opts?.isUnload) {
            this.setIsCollaborating(false)

            this.setActiveRoomLink(null)

            this.collaborators = new Map()

            this.excalidrawAPI.updateScene({
                collaborators: this.collaborators,
            })

            LocalData.resumeSave("collaboration")
        }
    }

    /**
     * Asynchronously fetch image files from Firebase based on provided elements and options.
     *
     * @param {Object} opts - Options object containing elements and forceFetchFiles flag
     * @param {readonly ExcalidrawElement[]} opts.elements - Elements to retrieve image files for
     * @param {boolean} [opts.forceFetchFiles] - Flag to force fetching files in certain conditions
     *
     * Indicates whether to fetch files that are errored or pending and older
     * than 10 seconds.
     *
     * Use this as a mechanism to fetch files which may be ok but for some
     * reason their status was not updated correctly.
     *
     * @returns {Promise<any>} - Promise resolving with fetched image files
     */
    private fetchImageFilesFromFirebase = async (opts: {
        elements: readonly ExcalidrawElement[]
        forceFetchFiles?: boolean
    }) => {
        const unfetchedImages = opts.elements
            .filter((element) => {
                return (
                    isInitializedImageElement(element) &&
                    !this.fileManager.isFileHandled(element.fileId) &&
                    !element.isDeleted &&
                    (opts.forceFetchFiles
                        ? element.status !== "pending" ||
                        Date.now() - element.updated > 10000
                        : element.status === "saved")
                )
            })
            .map((element) => (element as InitializedExcalidrawImageElement).fileId)

        return await this.fileManager.getFiles(unfetchedImages)
    }

    /**
     * Asynchronously decrypts the payload using the provided IV, encrypted data, and decryption key.
     *
     * @param {Uint8Array} iv - Initialization vector for decryption
     * @param {ArrayBuffer} encryptedData - Encrypted data to be decrypted
     * @param {string} decryptionKey - Key used for decryption
     * @returns {Promise<ValueOf<SocketUpdateDataSource>>} - Promise resolving to the decrypted data
     */
    private decryptPayload = async (
        iv: Uint8Array,
        encryptedData: ArrayBuffer,
        decryptionKey: string,
    ): Promise<ValueOf<SocketUpdateDataSource>> => {
        try {
            const decrypted = await decryptData(iv, encryptedData, decryptionKey)

            const decodedData = new TextDecoder("utf-8").decode(new Uint8Array(decrypted))

            return JSON.parse(decodedData)
        } catch (error) {
            window.alert(t("alerts.decryptFailed"))

            console.error(error)

            return {
                type: WSSub.INVALID_RESPONSE,
            }
        }
    }

    /**
     * fallback init handler
     * @private
     */
    private fallbackInitializationHandler: null | (() => any) = null

    /**
     * start collaboration
     * @param existingRoomLinkData
     */
    startCollaboration = async (existingRoomLinkData: null | {
        roomId: string;
        roomKey: string
    }): Promise<ImportedDataState | null> => {
        if (!this.state.username) {
            import("@excalidraw/random-username").then(({getRandomUsername}) => {
                const username = getRandomUsername()

                this.setUsername(username)
            })
        }

        if (this.portal.socket) return null

        let roomId

        let roomKey

        if (existingRoomLinkData) {
            ({roomId, roomKey} = existingRoomLinkData)
        } else {
            ({roomId, roomKey} = await Data.generateCollaborationLinkData())

            window.history.pushState(
                {},
                APP_NAME,
                Data.getCollaborationLink({roomId, roomKey}),
            )
        }

        const scenePromise = resolvablePromise<ImportedDataState | null>()

        this.setIsCollaborating(true)

        LocalData.pauseSave("collaboration")

        const {default: socketIOClient} = await import("socket.io-client")

        const fallbackInitializationHandler = () => {
            this.initializeRoom({
                roomLinkData: existingRoomLinkData,
                fetchScene: true,
            }).then((scene) => {
                scenePromise.resolve(scene)
            })
        }

        this.fallbackInitializationHandler = fallbackInitializationHandler

        try {
            this.portal.socket = this.portal.open(
                socketIOClient(import.meta.env.VITE_APP_WS_SERVER_URL, {
                    transports: ["websocket", "polling"],
                }),
                roomId,
                roomKey,
            )

            this.portal.socket.once("connect_error", fallbackInitializationHandler)
        } catch (error: any) {
            console.error(error)

            this.setErrorDialog(error.message)

            return null
        }

        if (!existingRoomLinkData) {
            const elements = this.excalidrawAPI.getSceneElements().map((element) => {
                if (isImageElement(element) && element.status === "saved") {
                    return newElementWith(element, {status: "pending"})
                }
                return element
            })

            /**
             * Remove deleted elements from the elements array and history to prevent exposure of potentially sensitive user data.
             * This step ensures that manually deleted existing elements (or cleared scene) are not persisted to the database.
             */

            this.excalidrawAPI.history.clear()

            this.excalidrawAPI.updateScene({
                elements,
                commitToHistory: true,
            })

            this.saveCollabRoomToFirebase(Data.getSyncableElements(elements))
        }

        /**
         * Fallback in case you're not alone in the room but still don't receive the initial SCENE_INIT message.
         */
        this.socketInitializationTimer = window.setTimeout(
            fallbackInitializationHandler,
            BaseConfig.INITIAL_SCENE_UPDATE_TIMEOUT,
        )

        /**
         * All socket listeners are moving to Portal
         */
        this.portal.socket.on(
            "client-broadcast",
            async (encryptedData: ArrayBuffer, iv: Uint8Array) => {

                if (!this.portal.roomKey) return

                const decryptedData = await this.decryptPayload(
                    iv,
                    encryptedData,
                    this.portal.roomKey,
                )

                switch (decryptedData.type) {
                    case WSSub.INVALID_RESPONSE:
                        return
                    case WSSub.INIT: {
                        if (!this.portal.socketInitialized) {
                            this.initializeRoom({fetchScene: false})

                            const remoteElements = decryptedData.payload.elements

                            const reconciledElements = this.reconcileElements(remoteElements)

                            this.handleRemoteSceneUpdate(reconciledElements, {
                                init: true,
                            })

                            /**
                             * noop if already resolved via init from firebase
                             */
                            scenePromise.resolve({
                                elements: reconciledElements,
                                scrollToContent: true,
                            })
                        }
                        break
                    }
                    case WSSub.UPDATE:
                        this.handleRemoteSceneUpdate(
                            this.reconcileElements(decryptedData.payload.elements),
                        )
                        break
                    case WSSub.MOUSE_LOCATION: {
                        const {pointer, button, username, selectedElementIds} =
                            decryptedData.payload

                        const socketId: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["socketId"] =
                            decryptedData.payload.socketId ||
                            // @ts-ignore legacy, see #2094 (#2097)
                            decryptedData.payload.socketID

                        this.updateCollaborator(socketId, {
                            pointer,
                            button,
                            selectedElementIds,
                            username,
                        })

                        break
                    }

                    case WSSub.USER_VISIBLE_SCENE_BOUNDS: {
                        const {sceneBounds, socketId} = decryptedData.payload

                        const appState = this.excalidrawAPI.getAppState()

                        /**
                         * Condition where we're not following the user (shouldn't happen, but could be a late message or bug upstream).
                         */
                        if (appState.userToFollow?.socketId !== socketId) {
                            console.warn(
                                `receiving remote client's (from ${socketId}) viewport bounds even though we're not subscribed to it!`,
                            )
                            return
                        }

                        /**
                         * cross-follow case, ignore updates in this case
                         */
                        if (
                            appState.userToFollow &&
                            appState.followedBy.has(appState.userToFollow.socketId)
                        ) {
                            return
                        }

                        this.excalidrawAPI.updateScene({
                            appState: zoomToFitBounds({
                                appState,
                                bounds: sceneBounds,
                                fitToViewport: true,
                                viewportZoomFactor: 1,
                            }).appState,
                        })

                        break
                    }

                    case WSSub.IDLE_STATUS: {
                        const {userState, socketId, username} = decryptedData.payload
                        this.updateCollaborator(socketId, {
                            userState,
                            username,
                        })
                        break
                    }

                    default: {
                        assertNever(decryptedData, null)
                    }
                }
            },
        )

        this.portal.socket.on("first-in-room", async () => {
            if (this.portal.socket) {
                this.portal.socket.off("first-in-room")
            }

            const sceneData = await this.initializeRoom({
                fetchScene: true,
                roomLinkData: existingRoomLinkData,
            })

            scenePromise.resolve(sceneData)
        })

        this.portal.socket.on(WSEvent.USER_FOLLOW_ROOM_CHANGE, (followedBy: SocketId[]) => {
            this.excalidrawAPI.updateScene({
                appState: {followedBy: new Set(followedBy)},
            })

            this.relayVisibleSceneBounds({force: true})
        })

        this.initializeIdleDetector()

        this.setActiveRoomLink(window.location.href)

        return scenePromise
    }

    /**
     * Asynchronously initializes the room based on provided options, fetching scene data if needed.
     *
     * @param {Object} options - Options object containing fetchScene and roomLinkData properties
     * @param {boolean} options.fetchScene - Flag indicating whether to fetch the scene
     * @param {Object | null} [options.roomLinkData] - Data containing roomId and roomKey for fetching scene
     * @returns {Promise<Object | null>} - Promise resolving with elements and scrollToContent flag if fetched, otherwise null
     */
    private initializeRoom = async ({fetchScene, roomLinkData,}: | {
        fetchScene: true; roomLinkData: { roomId: string; roomKey: string } | null
    } | { fetchScene: false; roomLinkData?: null }) => {
        clearTimeout(this.socketInitializationTimer!)

        if (this.portal.socket && this.fallbackInitializationHandler) {
            this.portal.socket.off(
                "connect_error",
                this.fallbackInitializationHandler,
            )
        }

        if (fetchScene && roomLinkData && this.portal.socket) {
            this.excalidrawAPI.resetScene()

            try {
                const elements = await FireBase.loadFromFirebase(
                    roomLinkData.roomId,
                    roomLinkData.roomKey,
                    this.portal.socket,
                )
                if (elements) {
                    this.setLastBroadcastedOrReceivedSceneVersion(
                        getSceneVersion(elements),
                    )

                    return {
                        elements,
                        scrollToContent: true,
                    }
                }
            } catch (error: any) {
                /**
                 * log the error and move on. other peers will sync us the scene.
                 */
                console.error(error)
            } finally {
                this.portal.socketInitialized = true
            }
        } else {
            this.portal.socketInitialized = true
        }

        return null
    }

    /**
     * Reconcile local elements with remote elements to produce a set of reconciled elements.
     *
     * @param {readonly ExcalidrawElement[]} remoteElements - Remote elements to reconcile against
     * @returns {ReconciledElements} - Reconciled elements after reconciliation process
     */
    private reconcileElements = (remoteElements: readonly ExcalidrawElement[]): ReconciledElements => {
        const localElements = this.getSceneElementsIncludingDeleted()

        const appState = this.excalidrawAPI.getAppState()

        remoteElements = restoreElements(remoteElements, null)

        const reconciledElements = Reconciliation.reconcileElements(
            localElements,
            remoteElements,
            appState,
        )

        /**
         * Prevent broadcasting the scene we just received to other collaborators.
         * Note: This needs to be set before updating the scene as it synchronously calls render.
         */
        this.setLastBroadcastedOrReceivedSceneVersion(
            getSceneVersion(reconciledElements),
        )

        return reconciledElements
    }

    /**
     * Throttled method to load image files asynchronously.
     */
    private loadImageFiles = throttle(async () => {
        const {loadedFiles, erroredFiles} =
            await this.fetchImageFilesFromFirebase({
                elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
            })

        this.excalidrawAPI.addFiles(loadedFiles)

        FileManager.updateStaleImageStatuses({
            excalidrawAPI: this.excalidrawAPI,
            erroredFiles,
            elements: this.excalidrawAPI.getSceneElementsIncludingDeleted(),
        })
    }, BaseConfig.LOAD_IMAGES_TIMEOUT)

    /**
     * Handle updating the scene with reconciled elements from a remote update.
     *
     * @param {ReconciledElements} elements - Reconciled elements to update the scene with
     * @param {Object} options - Options object containing init flag for committing to history
     */
    private handleRemoteSceneUpdate = (elements: ReconciledElements, {init = false}: { init?: boolean } = {}) => {
        this.excalidrawAPI.updateScene({
            elements,
            commitToHistory: !!init,
        })

        /**
         * Note: We haven't yet implemented multiplayer undo functionality, so we clear the undo stack
         * when we receive messages from another peer. This may cause a rough UX where if you undo,
         * another user makes a change, and you try to redo, your elements will be lost. We consider this
         * tradeoff appropriate for now.
         */
        this.excalidrawAPI.history.clear()

        this.loadImageFiles()
    }

    /**
     * Method triggered on pointer move event.
     * Clears idle timeout if set, sets a new idle timeout, and starts active interval if not already active.
     */
    private onPointerMove = () => {
        if (this.idleTimeoutId) {
            window.clearTimeout(this.idleTimeoutId)

            this.idleTimeoutId = null
        }

        this.idleTimeoutId = window.setTimeout(this.reportIdle, IDLE_THRESHOLD)

        if (!this.activeIntervalId) {
            this.activeIntervalId = window.setInterval(this.reportActive, ACTIVE_THRESHOLD)
        }
    }

    /**
     * Method triggered on visibility change.
     * Handles changes in user visibility, setting timeouts and intervals accordingly.
     */
    private onVisibilityChange = () => {
        if (document.hidden) {
            if (this.idleTimeoutId) {
                window.clearTimeout(this.idleTimeoutId)

                this.idleTimeoutId = null
            }

            if (this.activeIntervalId) {
                window.clearInterval(this.activeIntervalId)

                this.activeIntervalId = null
            }

            this.onIdleStateChange(UserIdleState.AWAY)
        } else {
            this.idleTimeoutId = window.setTimeout(this.reportIdle, IDLE_THRESHOLD)

            this.activeIntervalId = window.setInterval(this.reportActive, ACTIVE_THRESHOLD,)

            this.onIdleStateChange(UserIdleState.ACTIVE)
        }
    }

    /**
     * Method to report idle state and handle necessary cleanup.
     */
    private reportIdle = () => {
        this.onIdleStateChange(UserIdleState.IDLE)

        if (this.activeIntervalId) {
            window.clearInterval(this.activeIntervalId)

            this.activeIntervalId = null
        }
    }

    /**
     * Method to report active state.
     */
    private reportActive = () => {
        this.onIdleStateChange(UserIdleState.ACTIVE)
    }

    /**
     * Initialize the idle detector by adding event listeners for pointer move and visibility change.
     */
    private initializeIdleDetector = () => {
        document.addEventListener(EVENT.POINTER_MOVE, this.onPointerMove)

        document.addEventListener(EVENT.VISIBILITY_CHANGE, this.onVisibilityChange)
    }

    /**
     * Set collaborators based on the provided socket IDs.
     *
     * @param {SocketId[]} sockets - Array of socket IDs representing collaborators
     */
    setCollaborators = (sockets: SocketId[]) => {
        const collaborators: CollabInstance["collaborators"] = new Map()

        for (const socketId of sockets) {
            collaborators.set(socketId, Object.assign({}, this.collaborators.get(socketId), {
                isCurrentUser: socketId === this.portal.socket?.id,
            }))
        }

        this.collaborators = collaborators

        this.excalidrawAPI.updateScene({collaborators})
    }

    /**
     * Update a specific collaborator with provided updates.
     *
     * @param {SocketId} socketId - Socket ID of the collaborator to update
     * @param {Partial<Collaborator>} updates - Partial updates for the collaborator
     */
    updateCollaborator = (socketId: SocketId, updates: Partial<Collaborator>) => {
        const collaborators = new Map(this.collaborators)

        const user: Mutable<Collaborator> = Object.assign(
            {},
            collaborators.get(socketId),
            updates,
            {
                isCurrentUser: socketId === this.portal.socket?.id,
            },
        )

        collaborators.set(socketId, user)

        this.collaborators = collaborators

        this.excalidrawAPI.updateScene({collaborators,})
    }

    /**
     * Set the last broadcasted or received scene version.
     *
     * @param {number} version - Version to set
     */
    public setLastBroadcastedOrReceivedSceneVersion = (version: number) => {
        this.lastBroadcastedOrReceivedSceneVersion = version
    }

    /**
     * Get the last broadcasted or received scene version.
     *
     * @returns {number} - Last broadcasted or received scene version
     */
    public getLastBroadcastedOrReceivedSceneVersion = () => {
        return this.lastBroadcastedOrReceivedSceneVersion
    }

    /**
     * Get all scene elements including deleted ones.
     *
     * @returns {ExcalidrawElement[]} - Array of scene elements including deleted ones
     */
    public getSceneElementsIncludingDeleted = () => {
        return this.excalidrawAPI.getSceneElementsIncludingDeleted()
    }

    /**
     * Throttled method to handle pointer updates.
     *
     * @param {Object} payload - Data containing pointer information
     */
    onPointerUpdate = throttle((payload: {
        pointer: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["pointer"]
        button: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["button"]
        pointersMap: Gesture["pointers"]
    }) => {
        payload.pointersMap.size < 2 &&
        this.portal.socket &&
        this.portal.broadcastMouseLocation(payload)
    }, BaseConfig.CURSOR_SYNC_TIMEOUT)

    /**
     * Relay visible scene bounds to collaborators.
     *
     * @param {Object} [props] - Optional properties object
     */
    relayVisibleSceneBounds = (props?: { force: boolean }) => {
        const appState = this.excalidrawAPI.getAppState()

        if (this.portal.socket && (appState.followedBy.size > 0 || props?.force)) {
            this.portal.broadcastVisibleSceneBounds({
                sceneBounds: getVisibleSceneBounds(appState),
            }, `follow@${this.portal.socket.id}`)
        }
    }

    /**
     * Handle changes in user idle state by broadcasting the idle state to peers.
     *
     * @param {UserIdleState} userState - The user's current idle state
     */
    onIdleStateChange = (userState: UserIdleState) => {
        this.portal.broadcastIdleChange(userState)
    }

    /**
     * Broadcast elements to collaborators if the version is newer than the last broadcasted or received version.
     *
     * @param {readonly ExcalidrawElement[]} elements - Elements to broadcast
     */
    broadcastElements = (elements: readonly ExcalidrawElement[]) => {
        if (getSceneVersion(elements) > this.getLastBroadcastedOrReceivedSceneVersion()) {
            this.portal.broadcastScene(WSSub.UPDATE, elements, false)

            this.lastBroadcastedOrReceivedSceneVersion = getSceneVersion(elements)

            this.queueBroadcastAllElements()
        }
    }

    /**
     * sync elements
     * @param elements
     */
    syncElements = (elements: readonly ExcalidrawElement[]) => {
        this.broadcastElements(elements)

        this.queueSaveToFirebase()
    }

    /**
     * Throttled method to queue and broadcast all elements to collaborators.
     */
    queueBroadcastAllElements = throttle(() => {
        this.portal.broadcastScene(
            WSSub.UPDATE,
            this.excalidrawAPI.getSceneElementsIncludingDeleted(),
            true,
        )

        const currentVersion = this.getLastBroadcastedOrReceivedSceneVersion()

        const newVersion = Math.max(
            currentVersion,
            getSceneVersion(this.getSceneElementsIncludingDeleted()),
        )

        this.setLastBroadcastedOrReceivedSceneVersion(newVersion)
    }, BaseConfig.SYNC_FULL_SCENE_INTERVAL_MS)


    /**
     * Throttled method to queue saving scene to Firebase if socket is initialized.
     */
    queueSaveToFirebase = throttle(() => {
        if (this.portal.socketInitialized) {
            this.saveCollabRoomToFirebase(
                Data.getSyncableElements(
                    this.excalidrawAPI.getSceneElementsIncludingDeleted(),
                ),
            )
        }
    }, BaseConfig.SYNC_FULL_SCENE_INTERVAL_MS, {leading: false})

    /**
     * Set the username and save it to local storage.
     *
     * @param {string} username - The username to set
     */
    setUsername = (username: string) => {
        this.setState({username})

        LocalStorage.saveUsernameToLocalStorage(username)
    }

    /**
     * Get the stored username.
     *
     * @returns {string} - The stored username
     */
    getUsername = () => {
        return this.state.username
    }

    /**
     * Set the active room link and update the state.
     *
     * @param {string | null} activeRoomLink - The active room link to set
     */
    setActiveRoomLink = (activeRoomLink: string | null) => {
        this.setState({activeRoomLink})

        AppStore.app.set(CollabStore.activeRoomLink, activeRoomLink)
    }

    /**
     * Get the active room link.
     *
     * @returns {string | null} - The active room link
     */
    getActiveRoomLink = () => {
        return this.state.activeRoomLink
    }

    /**
     * Set the error indicator message in the application state.
     *
     * @param {string | null} errorMessage - The error message to display
     */
    setErrorIndicator = (errorMessage: string | null) => {
        AppStore.app.set(CollabStore.errorIndicator, {
            message: errorMessage,
            nonce: Date.now(),
        })
    }

    /**
     * Reset the error indicator in the application state.
     *
     * @param {boolean} [resetDialogNotifiedErrors=false] - Flag to reset dialog notified errors
     */
    resetErrorIndicator = (resetDialogNotifiedErrors = false) => {
        AppStore.app.set(CollabStore.errorIndicator, {message: null, nonce: 0})

        if (resetDialogNotifiedErrors) {
            this.setState({
                dialogNotifiedErrors: {},
            })
        }
    }

    /**
     * Set the error dialog message in the component state.
     *
     * @param {string | null} errorMessage - The error message to display in the dialog
     */
    setErrorDialog = (errorMessage: string | null) => {
        this.setState({
            errorMessage,
        })
    }


    /**
     * render
     */
    render() {
        const {errorMessage} = this.state

        return <>
            {errorMessage != null && (
                <ErrorDialog onClose={() => this.setErrorDialog(null)}>
                    {errorMessage}
                </ErrorDialog>
            )}
        </>
    }
}

declare global {
    interface Window {
        collab: CollabInstance
    }
}

if (import.meta.env.MODE === ENV.TEST || import.meta.env.DEV) {
    window.collab = window.collab || ({} as Window["collab"])
}

export default Collab
