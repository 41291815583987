import {
    compressData,
    decompressData,
} from "$/excalidraw/data/encode"
import {
    decryptData,
    generateEncryptionKey,
    IV_LENGTH_BYTES,
} from "$/excalidraw/data/encryption"
import {serializeAsJSON} from "$/excalidraw/data/json"
import {restore} from "$/excalidraw/data/restore"
import {ImportedDataState} from "$/excalidraw/data/types"
import {SceneBounds} from "$/excalidraw/element/bounds"
import {isInvisiblySmallElement} from "$/excalidraw/element/sizeHelpers"
import {isInitializedImageElement} from "$/excalidraw/element/typeChecks"
import {
    ExcalidrawElement,
    FileId,
} from "$/excalidraw/element/types"
import {t} from "$/excalidraw/i18n"
import {
    AppState,
    BinaryFileData,
    BinaryFiles,
    SocketId,
    UserIdleState,
} from "$/excalidraw/types"
import {bytesToHexString} from "$/excalidraw/utils"
import {FileManager} from "./FileManager"
import {BaseConfig} from "@/config/BaseConfig"
import {WSSub} from "@/enum/WSSub"
import {FireBase} from "./FireBase"

/**
 * Defines the possible results of exporting data to the backend.
 */
type ExportToBackendResult = | { url: null; errorMessage: string } | { url: string; errorMessage: null }

/**
 * Represents a Syncable Excalidraw Element with a special brand identifier.
 */
export type SyncableExcalidrawElement = ExcalidrawElement & {
    _brand: "SyncableExcalidrawElement"
}

/**
 * Defines the types of data that can be part of socket updates.
 */
export type SocketUpdateDataSource = {
    INVALID_RESPONSE: {
        type: WSSub.INVALID_RESPONSE
    }
    SCENE_INIT: {
        type: WSSub.INIT
        payload: {
            elements: readonly ExcalidrawElement[]
        }
    }
    SCENE_UPDATE: {
        type: WSSub.UPDATE
        payload: {
            elements: readonly ExcalidrawElement[]
        }
    }
    MOUSE_LOCATION: {
        type: WSSub.MOUSE_LOCATION
        payload: {
            socketId: SocketId
            pointer: { x: number; y: number; tool: "pointer" | "laser" }
            button: "down" | "up"
            selectedElementIds: AppState["selectedElementIds"]
            username: string
        }
    }
    USER_VISIBLE_SCENE_BOUNDS: {
        type: WSSub.USER_VISIBLE_SCENE_BOUNDS
        payload: {
            socketId: SocketId
            username: string
            sceneBounds: SceneBounds
        }
    }
    IDLE_STATUS: {
        type: WSSub.IDLE_STATUS
        payload: {
            socketId: SocketId
            userState: UserIdleState
            username: string
        }
    }
}

/**
 * Represents a Socket Update Data item with a brand identifier for differentiation.
 */
export type SocketUpdateData = SocketUpdateDataSource[keyof SocketUpdateDataSource] & {
    _brand: "socketUpdateData"
}

/**
 * This class provides utility functions related to data operations.
 */
export class Data {
    /**
     * URL for the backend GET requests.
     */
    static BACKEND_V2_GET = import.meta.env.VITE_APP_BACKEND_V2_GET_URL;

    /**
     * URL for the backend POST requests.
     */
    static BACKEND_V2_POST = import.meta.env.VITE_APP_BACKEND_V2_POST_URL;

    /**
     * Regular expression pattern for collaboration links.
     */
    static RE_COLLAB_LINK = /^#room=([a-zA-Z0-9_-]+),([a-zA-Z0-9_-]+)$/;

    /**
     * Check if an ExcalidrawElement is syncable and of type SyncableExcalidrawElement.
     * @param element The ExcalidrawElement to check.
     * @returns True if the element is syncable, false otherwise.
     */
    public static isSyncableElement = (element: ExcalidrawElement): element is SyncableExcalidrawElement => {
        if (element.isDeleted) {
            return element.updated > Date.now() - BaseConfig.DELETED_ELEMENT_TIMEOUT;
        }

        return !isInvisiblySmallElement(element)
    }

    /**
     * Get syncable elements from a list of ExcalidrawElements.
     * @param elements The array of ExcalidrawElements to filter.
     * @returns An array of SyncableExcalidrawElement items.
     */
    public static getSyncableElements = (elements: readonly ExcalidrawElement[]) => {
        return elements.filter((element) => this.isSyncableElement(element)) as SyncableExcalidrawElement[]
    }

    /**
     * Generate a room ID asynchronously.
     * @returns A promise that resolves to the generated room ID.
     */
    public static generateRoomId = async () => {
        const buffer = new Uint8Array(BaseConfig.ROOM_ID_BYTES)

        window.crypto.getRandomValues(buffer)

        return bytesToHexString(buffer)
    }

    /**
     * Check if a given link is a collaboration link.
     * @param link The link to check.
     * @returns True if the link is a valid collaboration link, false otherwise.
     */
    public static isCollaborationLink = (link: string) => {
        const hash = new URL(link).hash

        return this.RE_COLLAB_LINK.test(hash)
    }

    /**
     * Get collaboration link data from a provided link.
     * @param link The collaboration link.
     * @returns An object containing the roomId and roomKey extracted from the link.
     */
    public static getCollaborationLinkData = (link: string) => {
        const hash = new URL(link).hash

        const match = hash.match(this.RE_COLLAB_LINK)

        if (match && match[2].length !== 22) {
            window.alert(t("alerts.invalidEncryptionKey"))

            return null
        }

        return match ? {roomId: match[1], roomKey: match[2]} : null
    }

    /**
     * Generate collaboration link data asynchronously.
     * @returns A promise that resolves to an object containing the generated roomId and roomKey.
     */
    public static generateCollaborationLinkData = async () => {
        const roomId = await this.generateRoomId()

        const roomKey = await generateEncryptionKey()

        if (!roomKey) throw new Error("Couldn't generate room key")

        return {roomId, roomKey}
    }

    /**
     * Get the collaboration link based on roomId and roomKey.
     * @param data An object containing the roomId and roomKey.
     * @returns The collaboration link generated from the provided data.
     */
    public static getCollaborationLink = (data: {
        roomId: string
        roomKey: string
    }) => {
        return `${window.location.origin}${window.location.pathname}#room=${data.roomId},${data.roomKey}`
    }

    /**
     * Decodes shareLink data using the legacy buffer format.
     */
    public static legacyDecodeFromBackend = async ({buffer, decryptionKey}: {
        buffer: ArrayBuffer
        decryptionKey: string
    }) => {
        let decrypted: ArrayBuffer

        try {
            /**
             *  Buffer should contain both the IV (fixed length) and encrypted data
             */
            const iv = buffer.slice(0, IV_LENGTH_BYTES)

            const encrypted = buffer.slice(IV_LENGTH_BYTES, buffer.byteLength)

            decrypted = await decryptData(new Uint8Array(iv), encrypted, decryptionKey)
        } catch (error: any) {
            /**
             * Fixed IV (old format, backward compatibility)
             */
            const fixedIv = new Uint8Array(IV_LENGTH_BYTES)

            decrypted = await decryptData(fixedIv, buffer, decryptionKey)
        }

        /**
         * We need to convert the decrypted array buffer to a string
         */
        const string = new window.TextDecoder("utf-8").decode(new Uint8Array(decrypted))

        const data: ImportedDataState = JSON.parse(string)

        return {
            elements: data.elements || null,
            appState: data.appState || null,
        }
    }

    /**
     * Import data asynchronously from the backend.
     * @param id The ID of the data to import.
     * @param decryptionKey The key for decryption.
     * @returns A promise that resolves to the imported data state.
     */
    public static importFromBackend = async (
        id: string,
        decryptionKey: string,
    ): Promise<ImportedDataState> => {
        try {
            const response = await fetch(`${this.BACKEND_V2_GET}${id}`)

            if (!response.ok) {
                window.alert(t("alerts.importBackendFailed"))

                return {}
            }

            const buffer = await response.arrayBuffer()

            try {
                const {data: decodedBuffer} = await decompressData(new Uint8Array(buffer), {decryptionKey})

                const data: ImportedDataState = JSON.parse(new TextDecoder().decode(decodedBuffer),)

                return {
                    elements: data.elements || null,
                    appState: data.appState || null,
                }
            } catch (error: any) {
                console.warn(
                    "error when decoding shareLink data using the new format:",
                    error,
                )

                return this.legacyDecodeFromBackend({buffer, decryptionKey})
            }
        } catch (error: any) {
            window.alert(t("alerts.importBackendFailed"))

            console.error(error)

            return {}
        }
    }

    /**
     * Load the scene data asynchronously.
     * @param id The ID of the scene to load.
     * @param privateKey The private key for decryption.
     * @param localDataState The local data state to be restored.
     *  Supply local state even if importing from the backend to ensure we restore
     *  localStorage user settings which are not persisted on the server.
     *  This parameter is non-optional to ensure it is always provided, even if set to `undefined`.
     * @returns An object containing the elements, appState, files, and commitToHistory status.
     */
    public static loadScene = async (
        id: string | null,
        privateKey: string | null,
        localDataState: ImportedDataState | undefined | null,
    ) => {
        let data

        if (id != null && privateKey != null) {
            /**
             * The private key is used to decrypt the content from the server.
             * Take extra care not to leak it.
             */
            data = restore(
                await this.importFromBackend(id, privateKey),
                localDataState?.appState,
                localDataState?.elements,
                {repairBindings: true, refreshDimensions: false},
            )
        } else {
            data = restore(localDataState || null, null, null, {
                repairBindings: true,
            })
        }

        /**
         * Note: This will always be empty because we're not storing files
         * in the scene database/localStorage, and instead fetching them asynchronously
         * from a different database.
         */
        return {
            elements: data.elements,
            appState: data.appState,
            files: data.files,
            commitToHistory: false,
        }
    }


    /**
     * Export data to the backend asynchronously.
     * @param elements The array of ExcalidrawElements to export.
     * @param appState The partial application state to include in the export.
     * @param files The binary files associated with the elements.
     * @returns A promise that resolves to an ExportToBackendResult.
     */
    public static exportToBackend = async (
        elements: readonly ExcalidrawElement[],
        appState: Partial<AppState>,
        files: BinaryFiles,
    ): Promise<ExportToBackendResult> => {
        const encryptionKey = await generateEncryptionKey("string")

        const payload = await compressData(
            new TextEncoder().encode(serializeAsJSON(elements, appState, files, "database")),
            {encryptionKey}
        )

        try {
            const filesMap = new Map<FileId, BinaryFileData>()

            for (const element of elements) {
                if (isInitializedImageElement(element) && files[element.fileId]) {
                    filesMap.set(element.fileId, files[element.fileId])
                }
            }

            const filesToUpload = await FileManager.encodeFilesForUpload({
                files: filesMap,
                encryptionKey,
                maxBytes: BaseConfig.FILE_UPLOAD_MAX_BYTES,
            })

            const response = await fetch(this.BACKEND_V2_POST, {
                method: "POST",
                body: payload.buffer,
            })

            const json = await response.json()

            if (json.id) {
                const url = new URL(window.location.href)

                /**
                 * We need to store the key (and less importantly the id) as hash instead
                 * of queryParam in order to never send it to the server.
                 */

                url.hash = `json=${json.id},${encryptionKey}`

                const urlString = url.toString()

                await FireBase.saveFilesToFirebase({
                    prefix: `/files/shareLinks/${json.id}`,
                    files: filesToUpload,
                })

                return {url: urlString, errorMessage: null}
            } else if (json.error_class === "RequestTooLargeError") {
                return {
                    url: null,
                    errorMessage: t("alerts.couldNotCreateShareableLinkTooBig"),
                }
            }

            return {url: null, errorMessage: t("alerts.couldNotCreateShareableLink")}
        } catch (error: any) {
            console.error(error)

            return {url: null, errorMessage: t("alerts.couldNotCreateShareableLink")}
        }
    }
}
