import {Tooltip} from "$/excalidraw/components/Tooltip"
import {warning} from "$/excalidraw/components/icons"
import clsx from "clsx"
import {useEffect, useRef, useState} from "react"
import "./index.scss"

export type ErrorIndicator = {
    message: string | null;
    /** used to rerun the useEffect responsible for animation */
    nonce: number;
}

/**
 * Component for displaying collaboration errors
 *
 * @param {Object} props - Component props
 * @param {ErrorIndicator} props.collabError - The collabError object containing error details
 */
const CollabError = ({collabError}: { collabError: ErrorIndicator }) => {
    /**
     * State variable for animation status
     *
     * @type {[boolean, Function]} - A tuple where the first element is a boolean indicating if animation is ongoing and the second element is a function to update this state
     */
    const [isAnimating, setIsAnimating] = useState(false)

    /**
     * Reference for clearing animation
     *
     * @type {React.MutableRefObject<string | number | NodeJS.Timeout>}
     */
    const clearAnimationRef = useRef<string | number | NodeJS.Timeout>()

    /**
     * Effect hook for managing animation state based on collabError changes
     */
    useEffect(() => {
        setIsAnimating(true)

        clearAnimationRef.current = setTimeout(() => {
            setIsAnimating(false)
        }, 1000)

        return () => {
            clearTimeout(clearAnimationRef.current)
        }
    }, [collabError.message, collabError.nonce])

    if (!collabError.message) return null

    return <Tooltip label={collabError.message} long={true}>
        <div className={clsx("collab-errors-button", {
            "collab-errors-button-shake": isAnimating,
        })}>
            {warning}
        </div>
    </Tooltip>
}

CollabError.displayName = "CollabError"

export default CollabError
