export enum WSSub {
    /**
     * Response indicating an invalid request or response
     */
    INVALID_RESPONSE = "INVALID_RESPONSE",

    /**
     * Initial scene data initialization event
     */
    INIT = "SCENE_INIT",

    /**
     * Event for updating scene data
     */
    UPDATE = "SCENE_UPDATE",

    /**
     * Event for sending mouse location information
     */
    MOUSE_LOCATION = "MOUSE_LOCATION",

    /**
     * Event for indicating idle status
     */
    IDLE_STATUS = "IDLE_STATUS",

    /**
     * Event for sending the visible bounds of the scene to the user
     */
    USER_VISIBLE_SCENE_BOUNDS = "USER_VISIBLE_SCENE_BOUNDS",
}
