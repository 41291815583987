import {StrictMode} from "react"
import {createRoot} from "react-dom/client"
import {registerSW} from "virtual:pwa-register"
import DrawMindApp from "./App";

const rootElement = document.getElementById("root")!

const root = createRoot(rootElement)

registerSW()

root.render(<StrictMode><DrawMindApp/></StrictMode>)
