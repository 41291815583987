import React from "react"
import {OpenAIIcon} from "$/excalidraw/components/icons"
import {useI18n} from "$/excalidraw/i18n"
import {WelcomeScreen} from "$/excalidraw/index"

export const AppWelcomeScreen: React.FC<{
    onCollabDialogOpen: () => any
    isCollabEnabled: boolean
}> = React.memo((props) => {
    const {t} = useI18n()

    return (
        <WelcomeScreen>
            <WelcomeScreen.Hints.MenuHint>
                {t("welcomeScreen.app.menuHint")}
            </WelcomeScreen.Hints.MenuHint>
            <WelcomeScreen.Hints.ToolbarHint/>
            <WelcomeScreen.Hints.HelpHint/>
            <WelcomeScreen.Center>
                <WelcomeScreen.Center.Logo>
                    DrawMind
                </WelcomeScreen.Center.Logo>
                <WelcomeScreen.Center.Heading>
                    {t("welcomeScreen.app.center_heading")}
                </WelcomeScreen.Center.Heading>
                <WelcomeScreen.Center.Menu>
                    <WelcomeScreen.Center.MenuItemLoadScene/>
                    <WelcomeScreen.Center.MenuItemHelp/>
                    {props.isCollabEnabled && (
                        <WelcomeScreen.Center.MenuItemLiveCollaborationTrigger
                            onSelect={() => props.onCollabDialogOpen()}
                        />
                    )}
                    <WelcomeScreen.Center.MenuItemLink
                        href={`${import.meta.env.VITE_APP_GPT}/?source=drawmind&content=welcomeScreenGuest`}
                        shortcut={null}
                        icon={OpenAIIcon}
                    >
                        Try Ai DrawMind!
                    </WelcomeScreen.Center.MenuItemLink>
                </WelcomeScreen.Center.Menu>
            </WelcomeScreen.Center>
        </WelcomeScreen>
    )
})
