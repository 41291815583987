import {atom} from "jotai"


export class ShareStore {

    /**
     * share state
     */
    public static state = atom<{ isOpen: false } | { isOpen: true; type: 'collaborationOnly'|'share' }>({isOpen: false})

}
