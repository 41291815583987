import {atom} from "jotai"
import {CollabAPI} from "@/collab";
import type {PrimitiveAtom} from "jotai"
import type {ErrorIndicator} from "@/collab/error";

/**
 * Class representing the application store.
 */
export class CollabStore {

    /**
     * Atom to hold the CollabAPI instance or null.
     */
    public static collabAPI = atom<CollabAPI | null>(null)

    /**
     * Atom indicating whether collaboration is active.
     */
    public static isCollaborating = atom(false)

    /**
     * Atom indicating offline status.
     */
    public static isOffline = atom(false)

    /**
     * Atom to store the active room link or null if no active room.
     */
    public static activeRoomLink = atom<string | null>(null)

    /**
     * Atom for collabErrorIndicator
     *
     * @type {PrimitiveAtom<ErrorIndicator>}
     */
    public static errorIndicator = atom<ErrorIndicator>({
        message: null,
        nonce: 0,
    })
}
