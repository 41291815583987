import React from "react"
import {PlusPromoIcon, GithubIcon, OpenAIIcon} from "$/excalidraw/components/icons"
import {MainMenu} from "$/excalidraw/index"
import {LanguageList} from "./LanguageList"

export const AppMainMenu: React.FC<{
    onCollabDialogOpen: () => any
    isCollaborating: boolean
    isCollabEnabled: boolean
}> = React.memo((props) => {
    return (
        <MainMenu>
            <MainMenu.DefaultItems.LoadScene/>
            <MainMenu.DefaultItems.SaveToActiveFile/>
            <MainMenu.DefaultItems.Export/>
            <MainMenu.DefaultItems.SaveAsImage/>
            {props.isCollabEnabled && (
                <MainMenu.DefaultItems.LiveCollaborationTrigger
                    isCollaborating={props.isCollaborating}
                    onSelect={() => props.onCollabDialogOpen()}
                />
            )}

            <MainMenu.DefaultItems.Help/>
            <MainMenu.DefaultItems.ClearCanvas/>
            <MainMenu.Separator/>
            <MainMenu.ItemLink
                icon={OpenAIIcon}
                href={`${import.meta.env.VITE_APP_GPT}/?source=drawmind&utm_content=hamburger`}
                className="AI DrawMind"
            >
                AI
            </MainMenu.ItemLink>
            <MainMenu.ItemLink
                icon={GithubIcon}
                href={`https://github.com/wgx954418992/?source=drawmind&utm_content=hamburger`}
                className="Github"
            >
                Github
            </MainMenu.ItemLink>
            <MainMenu.Separator/>
            <MainMenu.DefaultItems.ToggleTheme/>
            <MainMenu.Separator/>
            <MainMenu.ItemCustom>
                <LanguageList style={{width: "100%"}}/>
            </MainMenu.ItemCustom>
            <MainMenu.Separator/>
            <MainMenu.DefaultItems.ChangeCanvasBackground/>
        </MainMenu>
    )
})
