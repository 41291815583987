export enum WSEvent {
    /**
     * Event for broadcasting messages to all connected clients including the sender
     */
    SERVER_VOLATILE = "server-volatile-broadcast",

    /**
     * Event for broadcasting messages to all connected clients except the sender
     */
    SERVER = "server-broadcast",

    /**
     * Event for notifying user follow changes
     */
    USER_FOLLOW_CHANGE = "user-follow",

    /**
     * Event for notifying user follow changes within a specific room
     */
    USER_FOLLOW_ROOM_CHANGE = "user-follow-room-change",
}
