import {
    Data,
    SocketUpdateData,
    SocketUpdateDataSource,
} from "@/data"

import {Collab} from "../index"
import {ExcalidrawElement} from "$/excalidraw/element/types"
import {
    OnUserFollowedPayload,
    SocketId,
    UserIdleState,
} from "$/excalidraw/types"
import {trackEvent} from "$/excalidraw/analytics"
import throttle from "lodash.throttle"
import {newElementWith} from "$/excalidraw/element/mutateElement"
import {BroadcastedExcalidrawElement} from "../reconciliation"
import {encryptData} from "$/excalidraw/data/encryption"
import {PRECEDING_ELEMENT_KEY} from "$/excalidraw/constants"
import type {Socket} from "socket.io-client"
import {WSSub} from "@/enum/WSSub"
import {WSEvent} from "@/enum/WSEvent"
import {BaseConfig} from "@/config/BaseConfig"

/**
 * Portal class representing a communication portal for managing socket interactions and room functionalities.
 * Contains methods for opening, closing connections, broadcasting data, and handling various events related to collaboration.
 */
class Portal {
    /**
     * Instance of TCollabClass for collaboration
     */
    collab: Collab

    /**
     * Socket instance for communication
     */
    socket: Socket | null = null

    /**
     * Flag indicating if the socket is fully initialized
     */
    socketInitialized: boolean = false // we don't want the socket to emit any updates until it is fully initialized

    /**
     * Room ID for tracking the current room
     */
    roomId: string | null = null

    /**
     * Room key for identification
     */
    roomKey: string | null = null

    /**
     * Map to store broadcasted element versions
     */
    broadcastedElementVersions: Map<string, number> = new Map()

    /**
     * constructor
     * @param collab
     */
    constructor(collab: Collab) {
        this.collab = collab
    }

    /**
     * Method to open a connection using the provided socket, room ID, and room key.
     * Initializes socket listeners and performs necessary actions for room setup.
     *
     * @param {Socket} socket - The socket instance for communication
     * @param {string} id - The room ID
     * @param {string} key - The room key
     * @returns {Socket} The initialized socket
     */
    open(socket: Socket, id: string, key: string) {
        this.socket = socket

        this.roomId = id

        this.roomKey = key

        /**
         * Initialize socket listeners
         */
        this.socket.on("init-room", () => {
            if (this.socket) {
                this.socket.emit("join-room", this.roomId)

                trackEvent("share", "room joined")
            }
        })

        this.socket.on("new-user", async (_socketId: string) => {
            this.broadcastScene(
                WSSub.INIT,
                this.collab.getSceneElementsIncludingDeleted(),
                true,
            )
        })

        this.socket.on("room-user-change", (clients: SocketId[]) => {
            this.collab.setCollaborators(clients)
        })

        return socket
    }

    /**
     * Method to close the connection by performing cleanup tasks and closing the socket.
     */
    close() {
        if (!this.socket) return

        this.queueFileUpload.flush()

        this.socket.close()

        this.socket = null

        this.roomId = null

        this.roomKey = null

        this.socketInitialized = false

        this.broadcastedElementVersions = new Map()
    }

    /**
     * Method to check if the connection is open by verifying socket initialization and room details.
     *
     * @returns {boolean} True if the connection is open, false otherwise
     */
    isOpen() {
        return !!(
            this.socketInitialized &&
            this.socket &&
            this.roomId &&
            this.roomKey
        )
    }

    /**
     * Method to broadcast socket data with optional volatility and room ID.
     *
     * @param {SocketUpdateData} data - The data to broadcast
     * @param {boolean} [volatile=false] - Flag indicating if the data is volatile
     * @param {string} [roomId] - Optional room ID
     */
    async _broadcastSocketData(
        data: SocketUpdateData,
        volatile: boolean = false,
        roomId?: string,
    ) {
        if (this.isOpen()) {
            const json = JSON.stringify(data)

            const encoded = new TextEncoder().encode(json)

            const {encryptedBuffer, iv} = await encryptData(this.roomKey!, encoded)

            this.socket?.emit(
                volatile ? WSEvent.SERVER_VOLATILE : WSEvent.SERVER,
                roomId ?? this.roomId,
                encryptedBuffer,
                iv,
            )
        }
    }

    /**
     * Throttled method for queuing file uploads.
     */
    queueFileUpload = throttle(async () => {
        try {
            await this.collab.fileManager.saveFiles({
                elements: this.collab.excalidrawAPI.getSceneElementsIncludingDeleted(),
                files: this.collab.excalidrawAPI.getFiles(),
            })
        } catch (error: any) {
            if (error.name !== "AbortError") {
                this.collab.excalidrawAPI.updateScene({
                    appState: {
                        errorMessage: error.message,
                    },
                })
            }
        }

        this.collab.excalidrawAPI.updateScene({
            elements: this.collab.excalidrawAPI
                .getSceneElementsIncludingDeleted()
                .map((element) => {
                    if (this.collab.fileManager.shouldUpdateImageElementStatus(element)) {
                        /**
                         * this will signal collaborators to pull image data from server
                         * (using mutation instead of newElementWith otherwise it'd break in-progress dragging)
                         */
                        return newElementWith(element, {status: "saved"})
                    }
                    return element
                }),
        })
    }, BaseConfig.FILE_UPLOAD_TIMEOUT)

    /**
     * Method to broadcast the scene with specified update type, elements, and synchronization flag.
     *
     * @param {WSSub.INIT | WSSub.UPDATE} updateType - The type of update (INIT or UPDATE)
     * @param {readonly ExcalidrawElement[]} allElements - All elements in the scene
     * @param {boolean} syncAll - Flag indicating whether to sync all elements
     */
    broadcastScene = async (
        updateType: WSSub.INIT | WSSub.UPDATE,
        allElements: readonly ExcalidrawElement[],
        syncAll: boolean,
    ) => {
        if (updateType === WSSub.INIT && !syncAll) {
            throw new Error("syncAll must be true when sending SCENE.INIT")
        }

        /**
         * sync out only the elements we think we need to to save bandwidth.
         * periodically we'll resync the whole thing to make sure no one diverges
         * due to a dropped message (server goes down etc).
         */
        const syncableElements = allElements.reduce(
            (acc, element: BroadcastedExcalidrawElement, idx, elements) => {
                if (
                    (syncAll ||
                        !this.broadcastedElementVersions.has(element.id) ||
                        element.version >
                        this.broadcastedElementVersions.get(element.id)!) &&
                    Data.isSyncableElement(element)
                ) {
                    acc.push({
                        ...element,
                        /**
                         * z-index info for the reconciler
                         */
                        [PRECEDING_ELEMENT_KEY]: idx === 0 ? "^" : elements[idx - 1]?.id,
                    })
                }
                return acc
            },
            [] as BroadcastedExcalidrawElement[],
        )

        const data: SocketUpdateDataSource[typeof updateType] = {
            type: updateType,
            payload: {
                elements: syncableElements,
            },
        }

        for (const syncableElement of syncableElements) {
            this.broadcastedElementVersions.set(
                syncableElement.id,
                syncableElement.version,
            )
        }

        this.queueFileUpload()

        await this._broadcastSocketData(data as SocketUpdateData)
    }

    /**
     * Method to broadcast idle status change with user state.
     *
     * @param {UserIdleState} userState - The user's idle state
     */
    broadcastIdleChange = (userState: UserIdleState) => {
        if (this.socket?.id) {
            const data: SocketUpdateDataSource["IDLE_STATUS"] = {
                type: WSSub.IDLE_STATUS,
                payload: {
                    socketId: this.socket.id as SocketId,
                    userState,
                    username: this.collab.state.username,
                },
            }

            return this._broadcastSocketData(data as SocketUpdateData, true)
        }
    }

    /**
     * Method to broadcast mouse location with pointer information.
     *
     * @param {Object} payload - The payload containing pointer and button information
     * @param {SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["pointer"]} payload.pointer - Pointer information
     * @param {SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["button"]} payload.button - Button information
     */
    broadcastMouseLocation = (payload: {
        pointer: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["pointer"]
        button: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["button"]
    }) => {
        if (this.socket?.id) {
            const data: SocketUpdateDataSource["MOUSE_LOCATION"] = {
                type: WSSub.MOUSE_LOCATION,
                payload: {
                    socketId: this.socket.id as SocketId,
                    pointer: payload.pointer,
                    button: payload.button || "up",
                    selectedElementIds:
                    this.collab.excalidrawAPI.getAppState().selectedElementIds,
                    username: this.collab.state.username,
                },
            }

            return this._broadcastSocketData(data as SocketUpdateData, true)
        }
    }

    /**
     * Method to broadcast visible scene bounds for a specific room.
     *
     * @param {Object} payload - The payload containing scene bounds information
     * @param {SocketUpdateDataSource["USER_VISIBLE_SCENE_BOUNDS"]["payload"]["sceneBounds"]} payload.sceneBounds - Scene bounds information
     * @param {string} roomId - The room ID
     */
    broadcastVisibleSceneBounds = (
        payload: {
            sceneBounds: SocketUpdateDataSource["USER_VISIBLE_SCENE_BOUNDS"]["payload"]["sceneBounds"]
        },
        roomId: string,
    ) => {
        if (this.socket?.id) {
            const data: SocketUpdateDataSource["USER_VISIBLE_SCENE_BOUNDS"] = {
                type: WSSub.USER_VISIBLE_SCENE_BOUNDS,
                payload: {
                    socketId: this.socket.id as SocketId,
                    username: this.collab.state.username,
                    sceneBounds: payload.sceneBounds,
                },
            }

            return this._broadcastSocketData(data as SocketUpdateData, true, roomId,)
        }
    }

    /**
     * Method to broadcast user followed event.
     *
     * @param {OnUserFollowedPayload} payload - The payload for user followed event
     */
    broadcastUserFollowed = (payload: OnUserFollowedPayload) => {
        if (this.socket?.id) {
            this.socket.emit(WSEvent.USER_FOLLOW_CHANGE, payload)
        }
    }
}

export default Portal
