import React from "react"
import {Card} from "$/excalidraw/components/Card"
import {ToolButton} from "$/excalidraw/components/ToolButton"
import {serializeAsJSON} from "$/excalidraw/data/json"
import {FireBase} from "@/data/FireBase"
import {
    FileId,
    NonDeletedExcalidrawElement,
} from "$/excalidraw/element/types"
import {
    AppState,
    BinaryFileData,
    BinaryFiles,
} from "$/excalidraw/types"
import {nanoid} from "nanoid"
import {useI18n} from "$/excalidraw/i18n"
import {
    encryptData,
    generateEncryptionKey,
} from "$/excalidraw/data/encryption"
import {isInitializedImageElement} from "$/excalidraw/element/typeChecks"
import {FileManager} from "@/data/FileManager"
import {MIME_TYPES} from "$/excalidraw/constants"
import {trackEvent} from "$/excalidraw/analytics"
import {getFrame} from "$/excalidraw/utils"
import {ExcalidrawLogo} from "$/excalidraw/components/ExcalidrawLogo"
import {BaseConfig} from "@/config/BaseConfig"

export const exportToExcalidrawPlus = async (
    elements: readonly NonDeletedExcalidrawElement[],
    appState: Partial<AppState>,
    files: BinaryFiles,
    name: string,
) => {
    const firebase = await FireBase.loadFirebaseStorage()

    const id = `${nanoid(12)}`

    const encryptionKey = (await generateEncryptionKey())!

    const encryptedData = await encryptData(
        encryptionKey,
        serializeAsJSON(elements, appState, files, "database"),
    )

    const blob = new Blob([encryptedData.iv, new Uint8Array(encryptedData.encryptedBuffer)], {type: MIME_TYPES.binary})

    await firebase
        .storage()
        .ref(`/migrations/scenes/${id}`)
        .put(blob, {
            customMetadata: {
                data: JSON.stringify({version: 2, name}),
                created: Date.now().toString(),
            },
        })

    const filesMap = new Map<FileId, BinaryFileData>()

    for (const element of elements) {
        if (isInitializedImageElement(element) && files[element.fileId]) {
            filesMap.set(element.fileId, files[element.fileId])
        }
    }

    if (filesMap.size) {
        const filesToUpload = await FileManager.encodeFilesForUpload({
            files: filesMap,
            encryptionKey,
            maxBytes: BaseConfig.FILE_UPLOAD_MAX_BYTES,
        })

        await FireBase.saveFilesToFirebase({
            prefix: `/migrations/files/scenes/${id}`,
            files: filesToUpload,
        })
    }

    window.open(`/import?excalidraw=${id},${encryptionKey}`,)
}

export const ExportToExcalidrawPlus: React.FC<{
    elements: readonly NonDeletedExcalidrawElement[]
    appState: Partial<AppState>
    files: BinaryFiles
    name: string
    onError: (error: Error) => void
    onSuccess: () => void
}> = ({elements, appState, files, name, onError, onSuccess}) => {
    const {t} = useI18n()
    return (
        <Card color="primary">
            <div className="Card-icon">
                <ExcalidrawLogo
                    style={{
                        [`--color-logo-icon` as any]: "#fff",
                        width: "2.8rem",
                        height: "2.8rem",
                    }}
                />
            </div>
            <h2>DrawMind+</h2>
            <div className="Card-details">
                {t("exportDialog.excalidrawplus_description")}
            </div>
            <ToolButton
                className="Card-button"
                type="button"
                title={t("exportDialog.excalidrawplus_button")}
                aria-label={t("exportDialog.excalidrawplus_button")}
                showAriaLabel={true}
                onClick={async () => {
                    try {
                        trackEvent("export", "eplus", `ui (${getFrame()})`)
                        await exportToExcalidrawPlus(elements, appState, files, name)
                        onSuccess()
                    } catch (error: any) {
                        console.error(error)
                        if (error.name !== "AbortError") {
                            onError(new Error(t("exportDialog.excalidrawplus_exportError")))
                        }
                    }
                }}
            />
        </Card>
    )
}
