import {
    createStore,
    entries,
    del,
    getMany,
    set,
    setMany,
    get,
} from "idb-keyval"
import {clearAppStateForLocalStorage} from "$/excalidraw/appState"
import {ImportedDataState} from "$/excalidraw/data/types"
import {clearElementsForLocalStorage} from "$/excalidraw/element"
import {
    ExcalidrawElement,
    FileId,
} from "$/excalidraw/element/types"
import {
    AppState,
    BinaryFileData,
    BinaryFiles,
} from "$/excalidraw/types"
import {MaybePromise} from "$/excalidraw/utility-types"
import {FileManager} from "./FileManager"
import {Locker} from "./Locker"
import {TabSync} from "./TabSync"
import {Local} from "../enum/Local"
import {LibraryPersistedData} from "$/excalidraw/data/library"
import {debounce} from "$/excalidraw/utils"

const filesStore = createStore("files-db", "files-store")

type SavingLockTypes = "collaboration"

/**
 * LocalFileManager
 */
class LocalFileManager extends FileManager {

    /**
     * Clear obsolete files from storage.
     * @param opts Options for clearing files, including currentFileIds.
     */
    clearObsoleteFiles = async (opts: { currentFileIds: FileId[] }) => {
        await entries(filesStore).then((entries) => {
            for (const [id, imageData] of entries as [FileId, BinaryFileData][]) {
                /**
                 * If an image is unused (not on the canvas) and is older than 1 day, delete it
                 * from storage. We check `lastRetrieved` because we care about the last time
                 * the image was used (loaded on canvas), not when it was initially created.
                 */
                if (
                    (!imageData.lastRetrieved ||
                        Date.now() - imageData.lastRetrieved > 24 * 3600 * 1000) &&
                    !opts.currentFileIds.includes(id as FileId)
                ) {
                    del(id, filesStore)
                }
            }
        })
    }
}


export class LocalData {
    /**
     * file storage
     */
    public static fileStorage = new LocalFileManager({
        /**
         * get files
         * @param ids
         */
        getFiles: (ids) => {
            return getMany(ids, filesStore).then(
                async (filesData: (BinaryFileData | undefined)[]) => {
                    const loadedFiles: BinaryFileData[] = []

                    const erroredFiles = new Map<FileId, true>()

                    const filesToSave: [FileId, BinaryFileData][] = []

                    filesData.forEach((data, index) => {
                        const id = ids[index]
                        if (data) {
                            const _data: BinaryFileData = {
                                ...data,
                                lastRetrieved: Date.now(),
                            }
                            filesToSave.push([id, _data])
                            loadedFiles.push(_data)
                        } else {
                            erroredFiles.set(id, true)
                        }
                    })

                    try {
                        /**
                         * save loaded files back to storage with updated `lastRetrieved`
                         */
                        setMany(filesToSave, filesStore)
                    } catch (error) {
                        console.warn(error)
                    }

                    return {loadedFiles, erroredFiles}
                },
            )
        },

        /**
         * save files
         * @param addedFiles
         */
        saveFiles: async ({addedFiles}) => {
            const savedFiles = new Map<FileId, true>()

            const erroredFiles = new Map<FileId, true>()

            /**
             * Before we use `storage` event synchronization, let's update the flag
             * optimistically. Hopefully nothing fails, and an IDB read executed
             * before an IDB write finishes will read the latest value.
             */
            TabSync.updateBrowserStateVersion(Local.LOCAL_STORAGE_VERSION_FILES)

            await Promise.all(
                [...addedFiles].map(async ([id, fileData]) => {
                    try {
                        await set(id, fileData, filesStore)
                        savedFiles.set(id, true)
                    } catch (error: any) {
                        console.error(error)
                        erroredFiles.set(id, true)
                    }
                }),
            )

            return {savedFiles, erroredFiles}
        },
    })

    /**
     * locker
     * @private
     */
    private static locker = new Locker<SavingLockTypes>()

    /**
     * save
     * @private
     */
    private static _save = debounce(async (elements: readonly ExcalidrawElement[], appState: AppState, files: BinaryFiles, onFilesSaved: () => void) => {
        this.saveDataStateToLocalStorage(elements, appState)

        await this.fileStorage.saveFiles({
            elements,
            files,
        })
        onFilesSaved()
    }, Local.SAVE_TO_LOCAL_STORAGE_TIMEOUT)

    /**
     * Saves DataState, including files. Bails if saving is paused
     * @param elements
     * @param appState
     * @param files
     * @param onFilesSaved
     */
    public static save = (
        elements: readonly ExcalidrawElement[],
        appState: AppState,
        files: BinaryFiles,
        onFilesSaved: () => void,
    ) => {
        /**
         * we need to make the `isSavePaused` check synchronously (undebounced)
         */
        if (!this.isSavePaused()) {
            this._save(elements, appState, files, onFilesSaved)
        }
    }

    /**
     * Flush the save operation.
     */
    public static flushSave = () => {
        this._save.flush()
    }

    /**
     * Pause the save operation based on the lock type provided.
     * @param lockType The type of lock to apply.
     */
    public static pauseSave = (lockType: SavingLockTypes) => {
        this.locker.lock(lockType)
    }

    /**
     * Resume the save operation based on the lock type provided.
     * @param lockType The type of lock to release.
     */
    public static resumeSave = (lockType: SavingLockTypes) => {
        this.locker.unlock(lockType)
    }

    /**
     * Check if the save operation is currently paused.
     * @returns True if save is paused due to hidden document or locking, false otherwise.
     */
    public static isSavePaused = () => {
        return document.hidden || this.locker.isLocked()
    }

    /**
     * Save data state to local storage after clearing and stringifying elements and appState.
     * @param elements The array of ExcalidrawElements to save.
     * @param appState The application state to save.
     */
    private static saveDataStateToLocalStorage = (elements: readonly ExcalidrawElement[], appState: AppState) => {
        try {
            localStorage.setItem(
                Local.LOCAL_STORAGE_ELEMENTS,
                JSON.stringify(clearElementsForLocalStorage(elements)),
            )

            localStorage.setItem(
                Local.LOCAL_STORAGE_APP_STATE,
                JSON.stringify(clearAppStateForLocalStorage(appState)),
            )

            TabSync.updateBrowserStateVersion(Local.LOCAL_STORAGE_VERSION_DATA_STATE)
        } catch (error: any) {
            /** Unable to access window.localStorage */
            console.error(error)
        }
    }
}

export class LibraryIndexedDBAdapter {
    /**
     * IndexedDB database and store name
     * @private
     */
    private static idb_name = Local.LOCAL_IDB_LIBRARY

    /**
     * library data store key
     * @private
     */
    private static key = "libraryData"

    /**
     * store
     * @private
     */
    private static store = createStore(`${LibraryIndexedDBAdapter.idb_name}-db`, `${LibraryIndexedDBAdapter.idb_name}-store`,)

    /**
     * load
     */
    public static load = async () => {
        const IDBData = await get<LibraryPersistedData>(
            LibraryIndexedDBAdapter.key,
            LibraryIndexedDBAdapter.store,
        )

        return IDBData || null
    }

    /**
     * save
     * @param data
     */
    public static save = (data: LibraryPersistedData): MaybePromise<void> => {
        return set(
            LibraryIndexedDBAdapter.key,
            data,
            LibraryIndexedDBAdapter.store,
        )
    }
}

/** LS Adapter used only for migrating LS library data
 * to indexedDB */
export class LibraryLocalStorageMigrationAdapter {
    /**
     * load
     */
    public static load = () => {
        const LSData = localStorage.getItem(Local.LOCAL_STORAGE_LEGACY_STORAGE_LIBRARY)

        if (LSData != null) {
            const libraryItems: ImportedDataState["libraryItems"] = JSON.parse(LSData)

            if (libraryItems) return {libraryItems}
        }
        return null
    }

    /**
     * clear
     */
    public static clear = () => {
        localStorage.removeItem(Local.LOCAL_STORAGE_LEGACY_STORAGE_LIBRARY)
    }
}
