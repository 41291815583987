import React from "react"
import {Footer} from "$/excalidraw/index"
import {EncryptedIcon} from "./EncryptedIcon"

export const AppFooter = React.memo(() => {
    return (
        <Footer>
            <div
                style={{
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                }}
            >
                <EncryptedIcon/>
            </div>
        </Footer>
    )
})
