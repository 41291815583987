export enum Local {
    /**
     * local storage elements
     */
    LOCAL_STORAGE_ELEMENTS = "excalidraw",

    /**
     * local storage app state
     */
    LOCAL_STORAGE_APP_STATE = "excalidraw-state",

    /**
     * local storage collab
     */
    LOCAL_STORAGE_COLLAB = "excalidraw-collab",

    /**
     * local storage theme
     */
    LOCAL_STORAGE_THEME = "excalidraw-theme",

    /**
     * local storage version date state
     */
    LOCAL_STORAGE_VERSION_DATA_STATE = "version-dataState",

    /**
     * local storage version files
     */
    LOCAL_STORAGE_VERSION_FILES = "version-files",

    /**
     * local idb name
     */
    LOCAL_IDB_LIBRARY = "excalidraw-library",

    /**
     * local storage legacy storage library
     */
    LOCAL_STORAGE_LEGACY_STORAGE_LIBRARY = "excalidraw-library",

    /**
     * local storage collab
     */
    SAVE_TO_LOCAL_STORAGE_TIMEOUT = 300,
}
