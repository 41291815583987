// place here categories that you want to track. We want to track just a
// small subset of categories at a given time.
const ALLOWED_CATEGORIES_TO_TRACK = ["ai"] as string[]

export const trackEvent = (
    category: string,
    action: string,
    label?: string,
    value?: number,
) => {
    try {
        if (typeof window === "undefined" || import.meta.env.VITE_WORKER_ID || import.meta.env.PROD) {
            return
        }

        if (!ALLOWED_CATEGORIES_TO_TRACK.includes(category)) {
            return
        }

        if (!import.meta.env.PROD) {
            console.info("trackEvent", {category, action, label, value})
        }
    } catch (error) {
        console.error("error during analytics", error)
    }
}
